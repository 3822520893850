import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Instagram, YouTube } from "@mui/icons-material";
import { FaCopy, FaYoutube } from "react-icons/fa6";
import toast from "react-hot-toast";

const formatFollowers = (count) => {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + "M";
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1) + "K";
  }
  //  else if (count > 0) {
  //   return (count / 1000).toFixed(2) + "K";
  // }
  return count;
};

function Profile({
  image,
  packageImage,
  id,
  igUserName,
  engagement_rate,
  yt_engagement_rate,
  platform = "instagram",
  price = 10,
  categories,
  followers,
  ytfollowers,
  overall_ratings,
  setOpenModal,
  handleModelAction,
  setLink,
  setLink2,
  lowestPacakgePrice,
}) {
  const navigate = useNavigate();

  const handleShareCopy = () => {
    const baseUrl = "http://www.cloutin.co";
    let shareLink;
    if (!engagement_rate || !yt_engagement_rate) {
      let pt = "instagram";
      if (yt_engagement_rate) {
        pt = "youtube";
      }
      shareLink = `${baseUrl}/share/${id}/${igUserName}/${
        engagement_rate || 0
      }/${pt}/price/${categories}/${followers}`;
    } else if (engagement_rate > yt_engagement_rate) {
      shareLink = `${baseUrl}/share/${id}/${igUserName}/${
        engagement_rate || 0
      }/${platform}/${price}/${categories}/${followers}`;
    } else {
      shareLink = `${baseUrl}/share/${id}/${igUserName}/${
        engagement_rate || 0
      }/youtube/${price}/${categories}/${followers}`;
    }
    navigator?.clipboard.writeText(shareLink);
    toast.success("Link copied to clipboard!", {
      duration: 1000,
    });
  };
  const handleNavigate = () => {
    if (!engagement_rate || !yt_engagement_rate) {
      let pt = "instagram";
      if (yt_engagement_rate) {
        pt = "youtube";
      }
      navigate(
        `/single/${id}/${igUserName}/${
          engagement_rate || 0
        }/${pt}/price/${categories}/${followers}`
      );
    } else if (engagement_rate > yt_engagement_rate) {
      navigate(
        `/single/${id}/${igUserName}/${
          engagement_rate || 0
        }/${platform}/${price}/${categories}/${followers}`
      );
    } else {
      navigate(
        `/single/${id}/${igUserName}/${
          engagement_rate || 0
        }/youtube/${price}/${categories}/${followers}`
      );
    }
    localStorage.setItem("imagelink", packageImage);
  };

  const totalStars = 5;
  const filledStars = Math.round(overall_ratings) || 0;

  return (
    <motion.div className="border border-[#ececec] px-4  w-full bg-white rounded-md my-5 p-10 shadow-md hover:shadow-lg relative profilepageMarketplace max-w-7xl mx-auto">
      <div className="absolute top-2 right-2 flex items-center gap-4">
        {/* <button
          className="rounded-full p-2 shadow-md"
          onClick={handleShareCopy}
        >
          <FaCopy />
        </button> */}
      </div>
      <div
        className="relative h-36 overflow-hidden rounded-t-md cursor-pointer"
        onClick={handleNavigate}
      >
        <img
          className="w-full h-full object-contain"
          src={image}
          alt="Profile"
          loading="lazy"
          fetchpriority="high"
        />
        {followers > 0 && (
          <div
            className="absolute bottom-0 left-0 px-2 py-1 text-white text-sm font-semibold rounded-tr-md flex items-center"
            style={{
              background:
                "linear-gradient(135deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%)",
            }}
          >
            <Instagram className="mr-1" fontSize="small" />
            {formatFollowers(followers)}
          </div>
        )}
        {ytfollowers > 0 && (
          <div
            className="absolute bottom-0 right-0 px-2 py-1 text-white text-sm font-semibold rounded-tl-md flex items-center"
            style={{
              background:
                "linear-gradient(135deg, #FF0000 0%, #FF4D4D 50%, #FF8080 100%)",
            }}
          >
            <FaYoutube className="mr-1" />
            {formatFollowers(ytfollowers)}
          </div>
        )}
      </div>

      <div className="cursor-pointer" onClick={handleNavigate}>
        <div className="py-0 mt-1 flex justify-between items-center w-full">
          <h1 className="font-bold text-lg">{igUserName}</h1>
        </div>

        {engagement_rate > 0 && (
          <div className="flex justify-between mt-4 mb-[1px]">
            <div>
              <h1 className="text-sm text-[#727272]">Instagram Engagement</h1>
              <h1 className="font-bold">
                {engagement_rate ? engagement_rate.toFixed(2) : 0}%
              </h1>
            </div>
            <img
              src="Images\Instagram_icon.png.webp"
              className="h-6 w-6"
              loading="lazy"
              fetchpriority="high"
            />
          </div>
        )}
        {yt_engagement_rate > 0 && (
          <div className="flex justify-between mt-4">
            <div>
              <h1 className="text-sm text-[#727272]">YouTube Engagement</h1>
              <h1 className="font-bold">
                {yt_engagement_rate ? yt_engagement_rate.toFixed(2) : 0}%
              </h1>
            </div>
            <YouTube className="text-red-500 h-6 w-6" />
          </div>
        )}

        <div className="flex justify-between mt-2">
          <div className="flex items-center">
            {overall_ratings === undefined ||
            overall_ratings === 0 ||
            overall_ratings === null ? (
              <h1 className="text-[12px] text-gray-700">No Ratings Yet</h1>
            ) : (
              Array.from({ length: Math.round(overall_ratings) }, (_, i) => (
                <img
                  key={i}
                  className="w-4 h-4"
                  src="/Images/star.png"
                  alt="star"
                  loading="lazy"
                  fetchpriority="high"
                />
              ))
            )}
          </div>
          <div className="flex items-center">
            <span className="text-gray-500 mr-[7px]">Price</span>
            <h1>
              &#8377;
              {lowestPacakgePrice}
            </h1>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Profile;
