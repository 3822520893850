
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { FaBell, FaTimes, FaTrash } from "react-icons/fa";
import {
  initializeSocket,
  registerUser,
  subscribeToNotifications,
  disconnectSocket,
} from "../socketManager";
import toast from "react-hot-toast";
import ApiInterceptor from "../utils/ApiInterceptor";

const SimplePopup = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const popupRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    initializeSocket();
    registerUser(userId);
    fetchNotifications();

    subscribeToNotifications(userId, (notification) => {
      addNotification(notification);
      playNotificationSound();
    });

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      disconnectSocket();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userId, location.pathname]);

  const fetchNotifications = async () => {
    try {
      const response = await ApiInterceptor.get(`notifications/${userId}`);
      setNotifications(response.data.reverse());
      updateUnreadCount(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const addNotification = (newNotification) => {
    setNotifications((prev) => [newNotification, ...prev]);
    setUnreadCount((prev) => prev + 1);
  };

  const updateUnreadCount = (notifs) => {
    const unread = notifs.filter((n) => n.new).length;
    setUnreadCount(unread);
  };

  const handleDelete = async (id) => {
    try {
      await ApiInterceptor.delete(`notifications/delete/${id}`);
      setNotifications((prev) => prev.filter((n) => n._id !== id));
      updateUnreadCount(notifications.filter((n) => n._id !== id));
    } catch (error) {
      toast.error("Error deleting notification", {
        duration: 1500,
      });
    }
  };

  const handleClearAll = async () => {
    try {
      await ApiInterceptor.delete(`notifications/deleteAll/${userId}`);
      setNotifications([]);
      setUnreadCount(0);
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setUnreadCount(0);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio("/assets/sound.wav");
    audio.play().catch((error) => console.log("Play sound error:", error));
  };

  return (
    <div className="relative" ref={popupRef}>
      <button
        onClick={togglePopup}
        className="relative p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
      >
        <FaBell className="w-6 h-6" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl z-[9999]">
          <div className="p-4 border-b flex justify-between items-center border-t-2 border-t-gray-400 rounded-t-md">
            <h3 className="text-lg font-semibold">Notifications</h3>
            {notifications.length > 0 && (
              <button
                onClick={handleClearAll}
                className="text-sm text-red-500 hover:text-red-700"
              >
                <FaTrash className="inline mr-1" />
                Clear All
              </button>
            )}
          </div>
          <div className="max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <p className="p-4 text-center text-gray-500">No notifications</p>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification._id}
                  className={`p-4 border-b ${
                    notification.new ? "bg-blue-50" : ""
                  }`}
                >
                  <div className="flex justify-between items-start bg-gray-100">
                    <div className="flex-1">
                      <p className="text-sm">{notification.notificationDesc}</p>
                      <p className="text-xs text-gray-500 mt-1">
                        {moment(notification.createdAt).fromNow()}
                      </p>
                    </div>
                    <button
                      onClick={() => handleDelete(notification._id)}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SimplePopup;
