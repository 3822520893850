import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";

function PaymentReceipt() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieve values from query parameters
  const email = searchParams.get("email");
  const name = searchParams.get("name");
  const dateTime = searchParams.get("dateTime");
  const paymentMethod = searchParams.get("paymentMethod");
  const amount = searchParams.get("amount");
  const item = searchParams.get("item");
  const currency = searchParams.get("currency");
  const tax = searchParams.get("tax");
  const status = searchParams.get("status");

  const logo = "https://app.cloutin.co/Images/logo/logo.jpg";
  const [loading, setLoading] = useState(true);

  // Handle loading and status changes
  useEffect(() => {
    setLoading(false);
  }, []);

  const handleDownload = () => {
    const doc = new jsPDF("p", "pt", "letter");

    // HTML content you want to add to the PDF
    const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Invoice</title>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: 0;">
            <div style="width: 765px; margin: 40px auto; padding: 20px; border: 1px solid #ddd;">
               <div style="display: grid; grid-template-columns: auto 1fr; align-items: center; margin-bottom: 20px;">
    <div>
    <img style="margin: 0; margin-left:15px;" height="30" width="30" src="https://app.cloutin.co/Images/logo/logo.jpg" />
    <h1 style="font-size: 20px">Cloutin</h1>
    </div>
</div>
                <div style="text-align: center; margin-bottom: 40px;">
                    <h1 style="font-size: 36px; margin: 0; font-weight: bold;">INVOICE</h1>
                    <p style="margin-left:5px; margin: 0; font-size: 14px;">Date: ${dateTime}</p>
                </div>
               <table style="width: 100%; margin-bottom: 20px; table-layout: fixed;">
    <tr>
        <td style="width: 50%; vertical-align: top;">
            <strong>Billed to:</strong><br>
            <p style="margin: 0;">${name}</p>
            <p style="margin: 0;">${email}</p>
        </td>
        <td style="width: 50%; vertical-align: top;  text-align:right;">
            <strong>From:</strong><br>
            <p style="margin: 0;">Cloutin</p>
            <p style="margin: 0;">P.NO. 267 K.NO 337 JODHPUR &nbsp; (&nbsp; M CORP &nbsp; )  PAL &nbsp; JODHPUR &nbsp; 342001</p>
            <p style="margin: 0;">contact@thedigitalshift.co</p>
        </td>
    </tr>
</table>

                

<table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
    <thead style="background-color: #f0f0f0;">
        <tr>
            <th style="text-align: left; border-bottom: 2px solid #ddd; padding: 10px; background-color: #d0d0d0;">Item</th>
            <th style="text-align: left; border-bottom: 2px solid #ddd; padding: 10px; background-color: #d0d0d0;">Currency</th>
            <th style="text-align: left; border-bottom: 2px solid #ddd; padding: 10px; background-color: #d0d0d0;">Price</th>
        </tr>
    </thead>
    <tbody style="background-color: #f9f9f9;">
        <tr>
            <td style="padding: 10px; border-bottom: 1px solid #ddd;">${item}</td>
            <td style="padding: 10px; border-bottom: 1px solid #ddd;">${currency}</td>
            <td style="padding: 10px; border-bottom: 1px solid #ddd;">${amount}</td>
        </tr>
    </tbody>
</table>


                <div style="text-align: right; margin-bottom: 20px;">
                    <p style="font-size: 18px; font-weight: bold; margin: 0;">Total: ${amount}</p>
                </div>
                <div style="text-align: right; margin-bottom: 20px;">
                    <p style="font-size: 18px; font-weight: bold; margin: 0;">Tax: ${tax}</p>
                </div>
                <div style="margin-bottom: 20px;">
                    <p style="margin: 0;"><strong>Payment method:</strong>${paymentMethod}</p>
                </div>
                <div style="margin-bottom: 20px;">
                    <p style="margin: 0;"><strong>Note:</strong> Thank you for choosing us!</p>
                </div>
                <div style="margin-bottom: 20px;">
                    <p style="margin: 0;">This invoice is generated digitally by computer</p>
                </div>
                <div style="background-color: #ddd; height: 100px; margin-top: 40px;">
                    <!-- Optional: Add a wave background here -->
                </div>
            </div>
        </body>
        </html>
    `;

    doc.html(htmlContent, {
      callback: function (pdf) {
        pdf.save("payment_receipt.pdf");
      },
      x: 10,
      y: 10,
      width: 500,
      windowWidth: 650,
    });
  };

  const calculateTotal = (items, taxes) => {
    const subtotal = items.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    );
    return subtotal + parseInt(taxes, 10);
  };


  return (

    <>

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          {status === "unpaid" && (

            <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
              <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg text-center">
              <div className="text-center mb-6">
                <img src={logo} alt="Logo" className="mx-auto mb-4 w-16 h-16" />
                <h1 className="text-2xl font-bold">Cloutin</h1>
              </div>
                <h1 className="text-3xl text-red-600 font-semibold mb-4">
                  Payment Cancelled
                </h1>
                <p className="text-gray-700 mb-8 font-semibold">
                  Your payment has been cancelled. If you have any questions,
                  please contact support.
                </p>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Continue to Website
                </button>
              </div>
            </div>
          )}
          {status === "error" && (
            <div className="text-center mb-6">
              <img src={logo} alt="Logo" className="mx-auto mb-4 w-16 h-16" />
              <h1 className="text-2xl font-bold">Cloutin</h1>
              <h2 className="text-xl font-semibold">Payment Receipt</h2>
              <p className="text-red-500 text-center mb-4">
                An Error Occurred
              </p>
            </div>
          )}
          {status !== "unpaid" && status !== "error" && (
            <div className="min-h-screen flex items-center justify-center bg-gray-100 ">
              <div className="max-w-md mx-auto bg-white p-8 rounded shadow-md w-full">
                <div className="flex flex-col items-center">
                  <img src={logo} width={50} alt="Logo" className="mb-4" />
                  <h2 className="text-3xl font-bold mb-4">Cloutin</h2>
                  <h2 className="text-2xl font-semibold mb-4">Payment Receipt</h2>
                </div>
                <table className="w-full mb-4 border-collapse border border-gray-300">
                  <thead className="bg-gray-500 text-white">
                    <tr>
                      <th className="border px-4 py-2">Item</th>
                      <th className="border px-4 py-2">Currency</th>
                      <th className="border px-4 py-2">Price</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-100">
                    <tr>
                      <td className="border px-4 py-2 font-semibold">
                        {item}
                      </td>
                      <td className="border px-4 py-2 font-semibold">
                        {currency}
                      </td>
                      <td className="border px-4 py-2 font-semibold">
                        ₹{amount}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="mb-4">
                  <p className=" flex justify-between font-semibold">
                    <span className="font-semibold">Username:</span>{" "}
                    {name}
                  </p>
                  <p className=" flex justify-between font-semibold">
                    <span className="font-semibold">Email:</span>{" "}
                    {email}
                  </p>
                  <p className=" flex justify-between font-semibold">
                    <span className="font-semibold">Payment Method:</span>{" "}
                    {paymentMethod}
                  </p>
                </div>
                <div className="mb-4">
                  <p className=" flex justify-between font-semibold">
                    <span className="font-semibold">Taxes:</span> ₹
                    {tax}
                  </p>
                  <p className=" flex justify-between font-semibold">
                    <span className="font-semibold">Total:</span> ₹
                    {amount}
                  </p>
                </div>
                <div className="mt-8 w-full flex items-center justify-between gap-2">
                  <button
                    onClick={handleDownload}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                  >
                    Download Receipt (PDF)
                  </button>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    Continue to Website
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>

  );
}

export default PaymentReceipt;
