import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaSync } from "react-icons/fa";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";

import { useDispatch } from "react-redux";
import { loginSuccess } from "../redux/Slice/userSlice";
import { MdOutlineCloudUpload } from "react-icons/md";
import ApiInterceptor from "../utils/ApiInterceptor";
import axios from "axios";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const recaptchaRef = useRef();

  // const handleCaptchaChange = async (value) => {
  //   try {
  //     const response = await ApiInterceptor.post("verify-captcha", {
  //       token: value,
  //     });

  //     if (response.status === 200) {
  //       setCaptcha(value);
  //     } else {
  //       toast.error("Captcha Verification failed");
  //     }
  //   } catch (error) {

  //     setCaptcha(null)

  //     if (error.response) {
  //       toast.error(`Captcha Verification expired: ${error.response.data.message || 'Please try again'}`);
  //     } else if (error.request) {
  //       toast.error("No response received. Please check your network connection.");
  //     } else {
  //       toast.error(`Error: ${error.message}`);
  //     }
  //   }
  // };

  const [isRotating, setIsRotating] = useState(false);

  const handleRefreshCaptcha = (e) => {
    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 500);

    e.preventDefault();
    setCaptcha(null);
    // recaptchaRef.current.reset();
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [cover, setCover] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleImage1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage1(file);
    };
    reader.onerror = () => {};
  };

  const uploadImage1 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading1(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/file1", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.publicUrl);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setLoading1(false);
    }
  };
  const dispatch = useDispatch();
  const signUp = async (e) => {
    e.preventDefault();

    const entry = {
      name: name,
      email: email,
      password: password,
      username: username,
      register_type: true,
      phone: phone,
      url: url,
      company: company,
      image: cover,
    };
    try {
      if (!name)
        return toast.error("Please enter your name", {
          duration: 1000,
          position: "top-center",
        });
      if (!email)
        return toast.error("Please enter your email", {
          duration: 1000,
          position: "top-center",
        });
      if (!validateEmail(email)) {
        return toast.error("Please enter valid email", {
          duration: 1000,
          position: "top-center",
        });
      }
      if (!username)
        return toast.error("Please enter your username", {
          duration: 1000,
          position: "top-center",
        });
      if (!password || password.trim() === "")
        return toast.error("Please enter your password", {
          duration: 1000,
          position: "top-center",
        });
      if (!phone)
        return toast.error("Please enter your phone number", {
          duration: 1000,
          position: "top-center",
        });

      if (!validatePhoneNumber(phone)) {
        return toast.error("Please enter a valid 10-digit phone number", {
          duration: 1000,
          position: "top-center",
        });
      }

      if (!url)
        return toast.error("Please enter your company website url", {
          duration: 1000,
          position: "top-center",
        });
      if (!company)
        return toast.error("Please enter your company name", {
          duration: 1000,
          position: "top-center",
        });
      if (confirmPassword === "" || confirmPassword.trim() === "") {
        return toast.error("Please enter confirm password", {
          duration: 1000,
          position: "top-center",
        });
      }
      if (
        password !== confirmPassword ||
        password.trim() !== confirmPassword.trim()
      ) {
        return toast.error("Password does not match", {
          duration: 1000,
          position: "top-center",
        });
      }

      // if (!cover)
      //   return toast.error("Please upload your profile photo", {
      //     duration: 1000,
      //     position: "top-center",
      //   });

      // if (!captcha) {
      //   return toast.error("Captcha Verification is required", {
      //     duration: 1000,
      //     position: "top-center",
      //   });
      // }

      const { data } = await ApiInterceptor.post("company/register", entry);
      setName("");
      setEmail("");
      setPassword("");
      setUrl("");
      setCompany("");
      setPhone("");
      setUsername("");
      setImage("");
      setConfirmPassword("");
      Cookies.set("username", data.username, { expires: 30, path: "/" });
      Cookies.set("name", data.name, { expires: 30, path: "/" });
      Cookies.set("userid", data.id, { expires: 30, path: "/" });
      Cookies.set("token", data.token, { expires: 30, path: "/" });
      Cookies.set("image", data.image, { expires: 30, path: "/" });
      localStorage.setItem("token", data?.token);

      dispatch(loginSuccess());
      toast.success("Register successfully");
      navigate(`/verify-email/${data?.id}`);
    } catch (error) {
      return toast.error(
        error?.response?.data?.message ||
          "Internal Server occured...Please try again later",
        {
          duration: 3000,
          position: "top-center",
        }
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/maindashboard");
    }
  }, [navigate]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(number)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="hidden md:flex md:w-1/2 lg:w-1/3 bg-gradient-to-b from-indigo-800 to-purple-700 text-white p-8 flex flex-col justify-center items-center md:items-start">
        <div className="mb-8 text-center md:text-left">
          {/* Company Branding */}
          {/* <h1 className="text-4xl md:text-5xl font-bold mb-2">Cloutin</h1> */}
          <img
            src="./Images/logo/cloutin_light.png"
            alt="Cloutin Logo"
            className="h-24 w-full object-cover"
          />
          <p className="text-gray-300">
            Marketplace, Influencer, and Campaigns
          </p>
        </div>
        <div className="max-w-md">
          {/* Company Features */}
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Features</h2>
          <ul className="list-disc pl-4 text-lg">
            <li className="mb-2">Seamless marketplace experience</li>
            <li className="mb-2">Connect with top influencers</li>
            <li className="mb-2">Powerful campaign management tools</li>
          </ul>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 bg-white p-8 flex flex-col justify-center mt-[2rem]">
        <div className="max-w-4xl w-full mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
            Join Cloutin
          </h2>

          <form
            className="grid grid-cols-1 md:grid-cols-2 gap-4"
            onSubmit={signUp}
          >
            {/* Name */}
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="block mb-2 font-bold text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Username */}
            <div className="flex flex-col">
              <label
                htmlFor="username"
                className="block mb-2 font-bold text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            {/* Company Name */}
            <div className="flex flex-col">
              <label
                htmlFor="companyName"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your company name"
                name="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>

            {/* Mobile Number */}
            <div className="flex flex-col">
              <label
                htmlFor="mobileNumber"
                className="block mb-2 font-bold text-gray-700"
              >
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobileNumber"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your mobile number"
                name="phone"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (regex.test(value)) {
                    setPhone(value);
                  }
                }}
              />
            </div>

            {/* Company Website URL */}
            <div className="flex flex-col">
              <label
                htmlFor="companyWebsite"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Website URL
              </label>
              <input
                type="url"
                id="companyWebsite"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your company website URL"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            {/* Company Email */}

            <div className="flex flex-col">
              <label
                htmlFor="companyEmail"
                className="block mb-2 font-bold text-gray-700"
              >
                Company Email
              </label>
              <div className="flex">
                <input
                  type="email"
                  id="companyEmail"
                  className="border border-gray-300 rounded-l-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter your company email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            {/* Password */}

            <div className="flex flex-col relative">
              <label
                htmlFor="password"
                className="block mb-2 font-bold text-gray-700"
              >
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10"
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {/* confirm password */}
            <div className="flex flex-col relative">
              <label
                htmlFor="password"
                className="block mb-2 font-bold text-gray-700"
              >
                Confirm Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10"
                  placeholder="Enter again your password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            {/* <div>
              <div className="border-2 border-gray-500 rounded-md inline-block px-2 py-2 text-blue-500">
                <label
                  htmlFor="file-upload"
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <MdOutlineCloudUpload
                    className="hover:text-blue-600"
                    size={24}
                  />
                  <span className="hover:text-blue-600">
                    {loading1 ? "Uploading..." : "Upload your profile photo"}
                  </span>
                </label>

                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  value={fileInputState1}
                  onChange={handleFileInputChange1}
                  className={`hidden ${loading1 ? "cursor-not-allowed" : ""}`}
                  disabled={loading1}
                />
              </div>
            </div>
            {previewAvatar && (
              <div className="w-20 h-20 rounded-full shadow-md bg-gray-200 flex items-center justify-center text-gray-500 font-bold mb-2 relative">
                <img
                  src={previewAvatar}
                  alt="Avatar Preview"
                  className="w-20 h-20 rounded-full object-cover"
                />
              </div>
            )} */}

            <div className="col-span-1 md:col-span-2 flex justify-center">
              {/* <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdZ7ewpAAAAAOslWJq1oC_cPdbpPbforwzWcJjl" // Replace with your reCAPTCHA site key
                onChange={handleCaptchaChange}
              /> */}
              {/* <button
                onClick={handleRefreshCaptcha}
                className="  m-3 px-4  text-black  hover:text-blue-600 flex items-center justify-center"
              >
                <FaSync className={`${isRotating?'animate-spin':''}`} />
              </button> */}
            </div>
            {/* Submit Button */}
            <div className="col-span-1 md:col-span-2">
              <button
                type="submit"
                className={"bg-indigo-600 text-white rounded-md px-4 py-2 w-full font-bold transition-colors duration-300"}
              >
                Join Cloutin
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <span className="text-gray-600">Already have an account?</span>
            <Link
              to="/login"
              className="ml-2 text-blue-500 font-semibold hover:underline"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
