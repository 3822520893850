import ApiInterceptor from "./ApiInterceptor";

const getAllData = (setData) => {
  ApiInterceptor.get("influencerMarketplaceInd")
    .then(({ data }) => {
      setData(data["result"]);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getAllPackages = (id, setPack, setPopularPackage, platform) => {
  ApiInterceptor.get(`packages?influencer_id=${id}`)
    .then(({ data }) => {
      let pack = data["packages"];
      pack = pack.filter((p) => p?.platform?.toLowerCase() === platform?.toLowerCase());
      const sortedPack = pack.slice().sort((a, b) => a.price - b.price);
      const popularPackage = sortedPack[Math.floor(pack.length / 2)];
      setPack(sortedPack);
      setPopularPackage([popularPackage]);
    })
    .catch((error) => {
      console.error("Error fetching packages:", error);
    });
};

const getAllBusiness = (setDataBusiness) => {
  ApiInterceptor.get("Business")
    .then(({ data }) => {
      setDataBusiness(data["company"]);
    })
    .catch((error) => {
      console.error("Error fetching business data:", error);
    });
};

const getAllCompaign = (setCampaign) => {
  ApiInterceptor.get("allcampaigns")
    .then(({ data }) => {
      setCampaign(data["campaigns"]);
    })
    .catch((error) => {
      console.error("Error fetching campaigns:", error);
    });
};

const getAllInfluencer = (setInfluencer) => {
  ApiInterceptor.get("influencer")
    .then(({ data }) => {
      setInfluencer(data["influencer"]);
    })
    .catch((error) => {
      console.error("Error fetching influencers:", error);
    });
};

export {
  getAllData,
  getAllPackages,
  getAllBusiness,
  getAllCompaign,
  getAllInfluencer,
};
