import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiInterceptor from "../../utils/ApiInterceptor";

const Details = () => {
  const location = useLocation();
  const transactionData = location.state;
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel modal
  const [rejectionReason, setRejectionReason] = useState(""); // State for storing rejection reason
  const navigate = useNavigate();

  const handleShowBankDetails = () => {
    setShowBankDetails(!showBankDetails);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleTransactionDone = async () => {
    try {
      await ApiInterceptor.put("admin/transactions/update/transaction/status", {
        id: transactionData._id,
        influencer_id: transactionData.influencer_id,
        amount: transactionData.Bank_Details.amount,
      });
      setShowSuccessModal(true);
      ApiInterceptor.post("notifications/post", {
        data: {
          influencerId: transactionData.influencer_id,
          notificationTitle: "WithdrawRequestAccepted",
          notificationDesc: "Your withdrawal request has been accepted",
          notificationDescforInfluencer :"Withdraw Request"
        }
      })
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate("/admin");
      }, 5000);
    } catch (error) {
      console.error("Error completing transaction:", error);
    }
  };

  return (
    <div className="container mx-auto p-6">
      {transactionData && (
        <div className="relative">
          <span className="absolute top-0 right-0 bg-red-600 text-white px-3 py-1 rounded-tl-md rounded-br-md shadow-md">
            Money Withdrawal Request
          </span>
          <h1 className="text-4xl font-bold mb-6 text-gray-800">
            Transaction Details
          </h1>
          <div className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
            <p className="text-2xl font-semibold text-blue-600 mb-4">
              Influencer Name: {transactionData.influencer_name}
            </p>
            <p className="text-lg text-gray-600 mb-2">
              Amount:{" "}
              <span className="text-green-600 font-bold">
                ₹{transactionData.Bank_Details.amount}
              </span>
            </p>
            <p className="text-lg text-gray-600 mb-4">
              Date: {formatDate(transactionData.updatedAt)}
            </p>

            <button
              onClick={handleShowBankDetails}
              className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md transition-transform transform hover:scale-105"
            >
              {showBankDetails ? "Hide" : "Show"} Bank Details
            </button>

            {showBankDetails && (
              <div className="mt-6 bg-gray-100 p-6 rounded-md shadow-inner">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Bank Details
                </h2>
                <p className="text-gray-600 mb-2">
                  Account Number:{" "}
                  <span className="font-medium">
                    {transactionData.Bank_Details.account_number}
                  </span>
                </p>
                <p className="text-gray-600 mb-2">
                  IFSC Code:{" "}
                  <span className="font-medium">
                    {transactionData.Bank_Details.ifscCode}
                  </span>
                </p>
                <p className="text-gray-600 mb-4">
                  Address:{" "}
                  <span className="font-medium">
                    {transactionData.Bank_Details.address}
                  </span>
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={handleTransactionDone}
                    className="bg-green-500 text-white px-6 py-2 rounded-md shadow-md transition-transform transform hover:scale-105"
                  >
                    Mark Transaction Done
                  </button>
                  <button
                    onClick={() => setShowCancelModal(true)} // Open cancel modal
                    className="bg-red-500 text-white px-6 py-2 rounded-md shadow-md transition-transform transform hover:scale-105"
                  >
                    Cancel Withdrawal Request
                  </button>
                </div>
              </div>
            )}
          </div>

          {showSuccessModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 transition-opacity">
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <p className="text-green-600 text-2xl mb-4">
                  Transaction Completed{" "}
                  <span role="img" aria-label="Tick">
                    &#x2705;
                  </span>
                </p>
                <p className="text-gray-700">
                  Redirecting to admin/transactions...
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Details;
