import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination, Avatar, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Chip } from "@mui/material";
import { FaEnvelope, FaUsers, FaCheckCircle } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiInterceptor from '../../utils/ApiInterceptor';


const Referrals = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReferrals(page);
  }, [page]);

  const fetchReferrals = async (currentPage) => {
    setLoading(true);
    try {
      const response = await ApiInterceptor.get(
        `refferal/getforadmin?page=${currentPage}&limit=5`
      );
      setData(response.data.data);
      setTotalPages(Math.ceil(response.data.total / response.data.limit));
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const extractNameFromEmail = (email) => {
    if (!email) return "N/A";
    const name = email.split("@")[0];
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <div className="min-h-screen bg-white py-10 px-4 transition-all">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        <FaUsers className="inline text-blue-500 mr-2" /> Referrals
      </h1>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <CircularProgress size={60} className="text-blue-500" />
        </div>
      ) : (
        <div className="space-y-6">
          {data && data.length > 0 ? (
            data.map((influencer) => (
              <div
                key={influencer._id}
                className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all transform hover:scale-102 duration-300 relative"
              >
                {/* Referral Badge placed in top-right corner */}
                {influencer.joinedViaReferralCode && (
                  <Chip
                    icon={<FaCheckCircle />}
                    label="Joined via Referral"
                    color="primary"
                    size="medium"
                    className="absolute top-4 right-4"
                  />
                )}

                <div className="flex items-center space-x-4">
                  {influencer.profile_image_url ? (
                    <img
                      src={influencer.profile_image_url}
                      alt="Profile"
                      className="w-16 h-16 rounded-full border-2 border-gray-200 object-cover"
                    />
                  ) : (
                    <Avatar
                      className="w-16 h-16 bg-blue-500 text-white font-semibold"
                    >
                      {(
                        influencer.name ||
                        extractNameFromEmail(influencer.email)
                      )
                        .charAt(0)
                        .toUpperCase()}
                    </Avatar>
                  )}
                  <div className="flex-1">
                    <h2 className="text-lg font-semibold text-gray-800 truncate">
                      {influencer.name || extractNameFromEmail(influencer.email)}
                    </h2>
                    <p className="flex items-center text-gray-600 text-sm">
                      <FaEnvelope className="mr-2 text-blue-500" />
                      {influencer.email || "N/A"}
                    </p>
                  </div>
                </div>

                {/* Show Referrer Information if joined via referral */}
                {influencer.joinedViaReferralCode && influencer.referrer && (
                  <div className="mt-4 p-4 bg-gray-50 rounded-xl shadow-sm border border-gray-200">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wide flex items-center">
                      Referred By
                      <FaCheckCircle className="ml-2 text-green-500" title="Verified Referral" />
                    </h3>
                    <div className="flex items-center mt-2">
                      {influencer.referrer.profile_image_url ? (
                        <img
                          src={influencer.referrer.profile_image_url}
                          alt="Referrer Profile"
                          className="w-14 h-14 rounded-full border border-gray-300 object-cover"
                        />
                      ) : (
                        <Avatar
                          className="w-14 h-14 bg-blue-500 text-white font-bold"
                        >
                          {(
                            influencer.referrer.name ||
                            extractNameFromEmail(influencer.referrer.email)
                          )
                            .charAt(0)
                            .toUpperCase()}
                        </Avatar>
                      )}
                      <div className="ml-4">
                        <h4 className="text-lg font-semibold text-gray-800">
                          {influencer.referrer.name ||
                            extractNameFromEmail(influencer.referrer.email)}
                        </h4>
                        <p className="text-sm text-gray-600">
                          {influencer.referrer.email || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Referred Influencers Section */}
                <Accordion className="mt-4" elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="referred-content"
                    id="referred-header"
                    className="bg-blue-50 hover:bg-blue-100 rounded-lg transition-all"
                  >
                    <p className="text-sm font-medium text-gray-600">
                      <FaUsers className="inline text-blue-500 mr-2" />
                      <span className="text-blue-600 font-semibold">
                        Referred Influencers
                      </span>{" "}
                      ({influencer.referredUsers.length})
                    </p>
                  </AccordionSummary>
                  <AccordionDetails className="bg-blue-50 rounded-lg shadow-md mt-2">
                    {influencer.referredUsers.length > 0 ? (
                      <div className="space-y-4">
                        {influencer.referredUsers.map((referred) => (
                          <div
                            key={referred._id}
                            className="flex items-center space-x-4 p-3 rounded-lg bg-white hover:bg-blue-100 shadow-sm transition-all duration-200"
                          >
                            {referred.profile_image_url ? (
                              <img
                                src={referred.profile_image_url}
                                alt="Referred Profile"
                                className="w-12 h-12 rounded-full border-2 border-gray-200 object-cover"
                              />
                            ) : (
                              <Avatar
                                className="w-12 h-12 bg-green-500 text-white font-semibold"
                              >
                                {(
                                  referred.name ||
                                  extractNameFromEmail(referred.email)
                                )
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            )}
                            <div>
                              <h4 className="text-sm font-semibold text-gray-800 truncate">
                                {referred.name ||
                                  extractNameFromEmail(referred.email)}
                              </h4>
                              <p className="text-gray-500 text-xs truncate">
                                {referred.email || "N/A"}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-500 text-xs">No referred users.</p>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">No influencers found.</div>
          )}
        </div>
      )}

      <div className="mt-10 flex justify-center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, value) => setPage(value)}
          color="primary"
        />
      </div>
    </div>
  );
};

export default Referrals;
