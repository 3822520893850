import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAffiliatesByAffiliateId, getInfluencerByInfluencerId } from "./Api";
import axios from "axios";
import AffiliateCampaignCard from "./AffiliateCampaignCard";
import { CgExternal } from "react-icons/cg";
import { TbDatabaseOff } from "react-icons/tb";
import AffiliateGraphReact from "./AffiliateGraphReact";
import toast from "react-hot-toast";
import { IoMdRefresh } from "react-icons/io";
import Loader from "../../utils/Loader";
import ApiInterceptor from "../../utils/ApiInterceptor";

function Affiliatehome() {
  const [affiliate, setAffiliate] = useState(null);
  const [influencerData, setInfluencerData] = useState([]);
  const { id4 } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [refreshClicked, setRefreshClicked] = useState(false);

  useEffect(() => {
    if (id4 || refreshClicked) {
      getAffiliatesByAffiliateId(setAffiliate, id4);
    }
  }, [id4, refreshClicked]);

  const [affid, setaffid] = useState("");

  useEffect(() => {
    setaffid(affiliate?._id);
  }, [affiliate]);

  const [dashboardData, setDahsboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (affid) {
          const response = await ApiInterceptor.get(
            `individualaffdetails/getindividualaffiliatedetails/${affid}`
          );
          setDahsboardData(response.data.campaigndetails);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [affid]);

  // open dailog box starts
  const [open, setOpen] = useState(false);

  const [apiData, setApiData] = useState(null);
  const [affiliatid, setaffilateid] = useState("");
  const [platformallowed, setplatformallowed] = useState("");
  const [influencerid, setinfluencerid] = useState("");

  useEffect(() => {
    setaffilateid(affiliate?._id);
    setplatformallowed(affiliate?.platform_allows);
    setinfluencerid("65977d40e659d02b60bc3151");
  }, [affiliate]);

  const [chartData, setChartData] = useState([]);
  const [percentageIncrease, setPercentageIncrease] = useState(null);

  useEffect(() => {
    if (chartData.length >= 2) {
      const latestData = chartData[chartData.length - 1];
      const yesterdayData = chartData[chartData.length - 2];

      const latestPurchase = latestData.purchases;
      const yesterdayPurchase = yesterdayData.purchases;

      if (yesterdayPurchase === 0 && latestPurchase === 0) {
        // Both yesterday's and latest data are zero
        setPercentageIncrease(0);
      } else {
        const increase = latestPurchase - yesterdayPurchase;
        const percentageIncrease =
          yesterdayPurchase !== 0
            ? ((increase / yesterdayPurchase) * 100).toFixed(2)
            : 100;

        setPercentageIncrease(percentageIncrease);
      }
    } else {
      // Reset percentage increase if there is not enough data for comparison
      setPercentageIncrease(null);
    }
  }, [chartData]);

  const fetchData = async () => {
    if (
      affiliate &&
      affiliate.influencers_applied &&
      affiliate.influencers_applied.length > 0 &&
      affiliatid &&
      platformallowed
    ) {
      try {
        const updatedData = await Promise.all(
          affiliate.influencers_applied.map(async (i) => {
            try {
              const response = await ApiInterceptor.get(
                `individualpostanalytics/?affiliate_id=${affiliatid}&platform_allows=${platformallowed}&influencer_id=${i.influencer_Id}`
              );
              const { data } = response;
              const engagementRate =
                ((data.likes + data.comments) / data.subscriberCount) * 100 || 0;
  
              return { ...i, additionalData: { ...data, engagementRate } };
            } catch (error) {
              console.error(
                `Error fetching data for influencer ID ${i.influencer_Id}:`,
                error
              );
              // Return a default object for the current influencer on error
              return { ...i, additionalData: { error: 'Failed to fetch data', engagementRate: 0 } };
            }
          })
        );
        setInfluencerData(updatedData);
      } catch (error) {
        console.error("Error updating influencer data:", error);
        // If Promise.all fails, set empty data as fallback
        setInfluencerData([]);
      }
    } else {
      // Handle case where prerequisites are not met
      console.warn("Missing required data to fetch influencer analytics.");
      setInfluencerData([]);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [affiliate, affiliatid, platformallowed, influencerid]);

  const approvedata = async (influencer_id, status) => {
    try {
      const response = await ApiInterceptor.post(
        "affiliate/updatepoststatus/",
        {
          influencer_id: influencer_id,
          status: status,
          affiliateId: affiliatid,
        }
      );

      setInfluencerData((prevItems) =>
        prevItems?.map((item) =>
          item.influencerId === influencer_id ? { ...item, status: 1 } : item
        )
      );

      // Close the modal after updating the state
      setOpen(false);
      // window.location.reload();
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error updating post status:", error);
    }
  };
  const getCurrentMonth = () => {
    return new Date().toLocaleString("default", { month: "long" });
  };

  const [selectedPeriod, setSelectedPeriod] = useState("Year");
  const [selectedYear, setSelectedYear] = useState(2025);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const renderYearOptions = () => {
    const years = [];
    for (let i = 2021; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const renderMonthOptions = () => {
    return months.map((month, index) => (
      <option key={index} value={month}>
        {month}
      </option>
    ));
  };
  const [loading, setLoading] = useState(false);
  const fetchGraphData = async () => {
    try {
      const selectedMonthIndex = months.indexOf(selectedMonth) + 1;
      const res = await ApiInterceptor.get(
        `affiliatedata/affiliateGraphData/${id4}?year=${selectedYear}&&month=${selectedMonthIndex}`
      );
      const Gdata = res?.data;
      setChartData(Gdata);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const handleRefresh = () => {
    setLoadingData(true);
    setTimeout(() => {
      setRefreshClicked((prev) => !prev);
      setLoadingData(false);
    }, 2000);
  };
  useEffect(() => {
    fetchGraphData();
  }, [selectedYear, selectedMonth]);

  return (
    <div className="p-4 md:p-6 lg:p-8 max-w-[2500px]">
      <div className="flex flex-col lg:flex-col gap-6">
        {/* Graph Section */}
        <div className="w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <div className="flex justify-between items-center p-4 flex-wrap">
            <h1 className="text-black font-extrabold text-lg">
              Total Sales Generated
            </h1>
            <div className="flex items-center space-x-2 mt-2 lg:mt-0">
              <span className="text-sm">
                Selected: {selectedMonth || "Current Month"} {selectedYear}
              </span>
              <select
                className="rounded-full py-1 px-2 text-sm focus:outline-none bg-white text-blue-500 border border-blue-300"
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="">Select Year</option>
                {renderYearOptions()}
              </select>
              <select
                className="rounded-full py-1 px-2 text-sm focus:outline-none bg-white text-blue-500 border border-blue-300"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="">Select Month</option>
                {renderMonthOptions()}
              </select>
            </div>
          </div>
          {loading ? (
            <div className="h-56 md:h-80 lg:h-[21rem] flex items-center justify-center">
              <div className="loader"></div>
              <h1 className="text-gray-500 text-2xl ml-2">Loading...</h1>
            </div>
          ) : (
            <div className="h-56 md:h-80 lg:h-[21rem]">
              {chartData.length === 0 ? (
                <div className="h-full flex items-center justify-center">
                  <TbDatabaseOff className="text-gray-500 text-5xl mb-2" />
                  <h1 className="text-gray-500 text-2xl ml-2">No Data Found</h1>
                </div>
              ) : (
                <AffiliateGraphReact chartData={chartData} />
              )}
            </div>
          )}
        </div>

        {/* Cards Section */}
        <div className="w-full  grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
          <AffiliateCampaignCard
            icon={"/Images/influencer_main.png"}
            dash_data={dashboardData?.clicks}
            dash_name={"Clicks"}
          />
          <AffiliateCampaignCard
            icon={"/Images/sales.png"}
            dash_data={dashboardData?.total_earning}
            dash_name={"Total Earning"}
          />
          <AffiliateCampaignCard
            icon={"/Images/influencer_main.png"}
            dash_data={
              dashboardData?.pending_influencers < 0
                ? 0
                : dashboardData?.pending_influencers
            }
            dash_name={"Pending Influencers"}
          />
          <AffiliateCampaignCard
            icon={"/Images/reach.png"}
            dash_data={dashboardData?.potential_reach}
            dash_name={"Potential Reach"}
          />
          <AffiliateCampaignCard
            icon={"/Images/social_main.png"}
            dash_data={dashboardData?.total_post}
            dash_name={"Total Post"}
          />
        </div>
      </div>

      {/* Influencer Table Section */}
      <div className="mt-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div>
            <h2 className="bg-[#D9FDFF] inline-block px-3 py-2 shadow-md text-xl rounded-sm mb-2">
              List of Influencers posted the content
            </h2>
            <h1 className="text-gray-600 text-xl">
              Affiliate Campaign:{" "}
              <span className="text-gray-800 uppercase">
                {affiliate?.affiliate_campaign_name}
              </span>
            </h1>
          </div>
          <div className="flex items-center">
            <IoMdRefresh
              onClick={handleRefresh}
              className={`cursor-pointer ${loadingData ? "animate-spin" : ""}`}
              size={30}
            />
          </div>
        </div>

        <InfluencerTable
          influencerData={influencerData}
          affiliate={affiliate}
          approveData={approvedata}
          loading={loadingData}
        />
      </div>
    </div>
  );
}

export default Affiliatehome;

export const InfluencerTable = ({
  influencerData,
  affiliate,
  approveData,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      default:
        return "Unknown";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "bg-yellow-100 text-yellow-800";
      case 1:
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = influencerData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left bg-white">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="py-3 px-6">
              Influencer
            </th>
            <th scope="col" className="py-3 px-6">
              Content
            </th>
            {affiliate?.platform_allows === "instagram" ? (
              <>
                <th scope="col" className="py-3 px-6">
                  Followers
                </th>
                <th scope="col" className="py-3 px-6">
                  Following
                </th>
              </>
            ) : (
              <th scope="col" className="py-3 px-6">
                Subscribers
              </th>
            )}
            <th scope="col" className="py-3 px-6">
              Engagement
            </th>
            <th scope="col" className="py-3 px-6">
              Reviews
            </th>
            <th scope="col" className="py-3 px-6">
              Add To Cart
            </th>
            <th scope="col" className="py-3 px-6">
              Purchases
            </th>
            <th scope="col" className="py-3 px-6">
              Post
            </th>
            <th scope="col" className="py-3 px-6">
              Status
            </th>
            <th scope="col" className="py-3 px-6">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div className="flex justify-center items-center h-[200px] w-full">
              <Loader loadingText={"Refreshing the data"} />
            </div>
          ) : (
            <>
              {currentItems.length > 0 ? (
                currentItems.map((i) => (
                  <>
                    {i?.additionalData?.name && (
                      <tr key={i._id} className="bg-white border-b">
                        <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                          {i?.additionalData?.name}
                        </td>
                        <td className="py-4 px-6">
                          {i?.additionalData?.content || 0}
                        </td>
                        {affiliate?.platform_allows === "instagram" ? (
                          <>
                            <td className="py-4 px-6">
                              {i?.additionalData?.followers || 0}
                            </td>
                            <td className="py-4 px-6">
                              {i?.additionalData?.following || 0}
                            </td>
                          </>
                        ) : (
                          <td className="py-4 px-6">
                            {i?.additionalData?.subscriberCount || 0}
                          </td>
                        )}
                        <td className="py-4 px-6">
                          {i?.additionalData?.engagementRate.toFixed(2)}%
                        </td>
                        <td className="py-4 px-6">
                          {i?.additionalData?.reviews || 0}
                        </td>
                        <td className="py-4 px-6">
                          {i?.additionalData?.add_to_carts}
                        </td>
                        <td className="py-4 px-6">
                          {i?.additionalData?.no_of_purchases}
                        </td>
                        <td className="py-4 px-6">
                          <a
                            href={i?.post_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline flex items-center"
                          >
                            View <CgExternal className="ml-1" />
                          </a>
                        </td>
                        <td className="py-4 px-6">
                          <span
                            className={`px-2 py-1 font-semibold text-xs rounded-full ${getStatusClass(
                              i?.status
                            )}`}
                          >
                            {getStatus(i?.status)}
                          </span>
                        </td>
                        <td className="py-4 px-6">
                          {i.status === 0 ? (
                            <button
                              onClick={() => approveData(i.influencer_Id, 1)}
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-3"
                            >
                              Approve
                            </button>
                          ) : null}
                          <button className="font-medium text-red-600 dark:text-red-500 hover:underline">
                            Report
                          </button>
                        </td>
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <tr className="col-11 py-4 px-6 font-medium text-gray-900 text-center">
                  No Influencer Found
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <nav
        className="flex justify-center items-center space-x-2 pt-4 pb-8"
        aria-label="Table navigation"
      >
        {Array.from({
          length: Math.ceil(influencerData.length / itemsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`px-3 py-1 rounded-md ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-500 hover:bg-gray-100"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </nav>
    </div>
  );
};
