import React, { useEffect, useState } from "react";
import axios from "axios";
import { Avatar } from "@mui/material";
import { FaCheckCircle, FaTimesCircle, FaSearch } from "react-icons/fa";
import { Box, Pagination } from "@mui/material";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import ApiInterceptor from "../../../utils/ApiInterceptor";

const Businesses_Admin = () => {
  const [influencers, setInfluencers] = useState([]);
  const [filteredInfluencers, setFilteredInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiInterceptor.get(
          "influencer/getinfluencerforadmin"
        );
        setInfluencers(response.data);
        setFilteredInfluencers(response.data); // Initially show all data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching influencer data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle search functionality
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const query = e.target.value.toLowerCase();
    const filtered = influencers.filter((influencer) =>
      influencer.name?.toLowerCase().startsWith(query) // Strict match: starts with query
    );
    setFilteredInfluencers(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  // Pagination Logic
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInfluencers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSuspend = (id) => {
    alert(`Suspend clicked`);
  };

  const getRelevantData = (value, fallback) => value || fallback;

  const extractNameFromEmail = (email) => {
    if (!email) return "Unknown";
    return email.split("@")[0];
  };

  return (
    <div className="p-6 bg-gradient-to-b from-gray-50 to-gray-200 min-h-screen">
      <h1 className="text-4xl font-extrabold text-center mb-6 text-gray-800 shadow-lg py-4 bg-white rounded-lg">
        Influencers
      </h1>

      {/* Search Bar */}
      <div className="mb-6 flex items-center bg-white rounded-lg shadow px-4 py-2">
        <FaSearch className="text-gray-500 mr-2" />
        <input
          type="text"
          placeholder="Search influencers by name"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full px-4 py-2 border-none focus:outline-none focus:ring-0"
        />
      </div>

      {loading ? (
        <div className="text-center text-lg font-semibold text-gray-600">
          Loading...
        </div>
      ) : (
        <>
          <div
            className="overflow-x-auto bg-white shadow-2xl rounded-xl p-4"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#c1c1c1 transparent",
            }}
          >
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100 text-gray-700 text-sm uppercase leading-normal shadow">
                  <th className="py-3 px-6 text-center">S.No</th>
                  <th className="py-3 px-6 text-center">Profile</th>
                  <th className="py-3 px-6 text-center">Name</th>
                  <th className="py-3 px-6 text-center">Email</th>
                  <th className="py-3 px-6 text-center">Registration Date</th>
                  <th className="py-3 px-6 text-center">Email Verified</th>
                  <th
                    className="py-3 px-6 text-center"
                    style={{ minWidth: "300px" }} // Increased width for Address column
                  >
                    Address
                  </th>
                  <th className="py-3 px-6 text-center">Bank Account</th>
                  <th className="py-3 px-6 text-center">KYC Verified</th>
                  <th className="py-3 px-6 text-center">Campaigns</th>
                  <th className="py-3 px-6 text-center">Earnings</th>
                  <th className="py-3 px-6 text-center">Orders</th>
                  <th className="py-3 px-6 text-center">Affiliates</th>
                  <th className="py-3 px-6 text-center">Instagram</th>
                  <th className="py-3 px-6 text-center">YouTube</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-medium">
                {currentItems.map((influencer, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-300 hover:bg-gray-50 transition duration-300"
                  >
                    <td className="py-3 px-6 text-center">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.profilePicture ? (
                        <img
                          src={influencer.profilePicture}
                          alt={getRelevantData(
                            influencer.name,
                            extractNameFromEmail(influencer.email)
                          )}
                          className="w-10 h-10 rounded-full object-cover mx-auto border border-gray-300 shadow-md"
                        />
                      ) : (
                        <Avatar className="mx-auto">
                          {getRelevantData(
                            influencer.name,
                            extractNameFromEmail(influencer.email)
                          )
                            .charAt(0)
                            .toUpperCase()}
                        </Avatar>
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {getRelevantData(
                        influencer.name,
                        extractNameFromEmail(influencer.email)
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {getRelevantData(
                        influencer.email,
                        "No Email Provided"
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {new Date(influencer.registrationDate).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.isEmailVerified ? (
                        <FaCheckCircle className="text-green-500 mx-auto" />
                      ) : (
                        <FaTimesCircle className="text-red-500 mx-auto" />
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {getRelevantData(
                        influencer.address,
                        "No Address Provided"
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.BankAccountAdded ? "Yes" : "No"}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.kycVerified === 1 ? "Yes" : "No"}
                    </td>
                    <td className="py-3 px-6 text-center">
                      Applied: {influencer.campaignsApplied} <br />
                      Completed: {influencer.campaignsCompleted}
                    </td>
                    <td className="py-3 px-6 text-center">
                      ₹{influencer.totalEarnings.toFixed(2)}
                    </td>
                    <td className="py-3 px-6 text-center">
                      Received: {influencer.ordersRecieved} <br />
                      Completed: {influencer.ordersCompleted}
                    </td>
                    <td className="py-3 px-6 text-center">
                      Applied: {influencer.affliateCampaignsApplied} <br />
                      Completed: {influencer.affliateCampaignsCompleted}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.instaData ? (
                        <div className="flex flex-col items-center">
                          <FaInstagram className="text-pink-600 mb-1" />
                          Followers: {influencer.instaData.followers} <br />
                          Following: {influencer.instaData.followings} <br />
                          Engagement:{" "}
                          {influencer.instaData.engagementRate.toFixed(2)}%
                        </div>
                      ) : (
                        "Instagram Not Verified"
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      {influencer.ytData ? (
                        <div className="flex flex-col items-center">
                          <FaYoutube className="text-red-500 mb-1" />
                          Subscribers: {influencer.ytData.subscribers} <br />
                          Engagement:{" "}
                          {influencer.ytData.engagementRate.toFixed(2)}%
                        </div>
                      ) : (
                        "YouTube Not Verified"
                      )}
                    </td>
                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => handleSuspend(influencer.id)}
                        className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200 shadow"
                      >
                        Suspend
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Component */}
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(filteredInfluencers.length / itemsPerPage)}
              page={currentPage}
              onChange={(event, page) => handlePageChange(page)}
              color="primary"
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Businesses_Admin;
