import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllPackages } from "../utils/HandleApi";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import toast from "react-hot-toast";
// import Cookie from "js-cookie";
import axios from "axios";
import Loader from "../utils/Loader";
import { Instagram, KeyboardArrowDownOutlined } from "@mui/icons-material";
import ErrorPage from "../utils/ErrorPage";
import ApiInterceptor from "../utils/ApiInterceptor";
import { FaExternalLinkAlt } from "react-icons/fa";
function SinglePage() {
  const [details, setDetails] = useState({});
  const igUserName = details?.userHandle;
  const engagement_rate = details?.engagement_rate;
  const price = details?.price;
  const categories = details?.categories;
  const followers = details?.followers;
  const engagement_rate_per_post = details?.engagement_rate_per_post;
  const { id1, platform } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await ApiInterceptor.get(
          `influencerMarketplaceInd/${id1}/${platform}`
        );
        setLoading(false);
        setDetails(response?.data);
      } catch (error) {
        setDetails("No Data");

        setLoading(false);
      }
    };
    fetchDetails();
  }, [navigate]);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [isValidUrl, setIsValidUrl] = useState(true);

  const [image, setImage] = useState(null);
  const [pack, setPack] = useState([]);
  const [popularPackage, setPopularPackage] = useState([]);

  const [selectedPackage, setSelectedPackage] = useState(platform);
  // const [popularPackage, setPopularPackage] = useState({});
  useEffect(() => {
    setImage(localStorage.getItem("imagelink"));
    getAllPackages(id1, setPack, setPopularPackage, platform);
  }, [selectedPackage]);

  const getinfluencers = (popular, name, price, id) => {
    const params = new URLSearchParams();
    params.append("name", name);
    params.append("price", price);
    params.append("id", id);
    params.append("platform", platform);

    ApiInterceptor.put(
      `packageAnalytics/updateclicks?packageId=${id}&influencerId=${id1}`
    )
      .then(() => {
        navigate(
          `/influencerinfo/${id1}?popular=${popular}&${params.toString()}`
        );
      })
      .catch((error) => {
        setErrors(true);
      });
  };

  const [influencerReview, setInfluencerReview] = useState([]);
  const [expandedReviews, setExpandedReviews] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiInterceptor.get(
          `review/getreviews?inf_id=${id1}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setInfluencerReview(response?.data?.reviews);
      } catch (error) {
        setErrors(true);
      } finally {
        setErrors(false);
      }
    };
    fetchData();
  }, []);
  const toggleReadMore = (index) => {
    setExpandedReviews((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [visibleReviews, setVisibleReviews] = useState(5);

  const handleSeeMore = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 5);
  };
  function removeSpecialCharacters(word) {
    const regex = /[^a-zA-Z0-9]/g;
    // Replace non-alphanumeric characters with an empty string
    return word.key.replace(regex, " ").toUpperCase();
  }

  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectPackage = (packageType) => {
    if (packageType === "youtube" && params.platform !== "youtube") {
      navigate(
        `/single/${params.id1}/${params.igUserName}/${params.engagement_rate || 0
        }/youtube/${params.price}/${params.categories}/${params.followers}`
      );
      setLoading(true);
    } else if (packageType === "instagram" && params.platform !== "instagram") {
      navigate(
        `/single/${params.id1}/${params.igUserName}/${params.engagement_rate || 0
        }/instagram/${params.price}/${params.categories}/${params.followers}`
      );
      setLoading(true);
    }
    setSelectedPackage(packageType);
    setIsOpen(false);
  };
  return (
    <>
      {loading ? (
        <Loader loadingText={"Data"} />
      ) : (
        <div className=" p-[20px] w-full md:p-2 8k:overflow-y-auto h-auto max-w-7xl mx-auto">
          {errors && <ErrorPage />}
          {params.price !== "price" ? (
            <div className="flex justify-end pb-2 mb-2">
              <button
                onClick={handleToggle}
                className="bg-blue-500 text-white font-sm px-2 py-1 rounded-md"
              >
                {selectedPackage === "instagram"
                  ? " Instagram Packages"
                  : "YouTube Packages"}
                <KeyboardArrowDownOutlined />
              </button>
              {isOpen && (
                <div className="absolute mt-10 w-56 rounded-md shadow-lg bg-[#F5F5F5] z-10">
                  <button
                    className="block py-2 px-4 text-gray-800 hover:bg-gray-200 w-full text-left"
                    onClick={() => handleSelectPackage("instagram")}
                  >
                    View Instagram Packages
                  </button>
                  <button
                    className="block py-2 px-4 text-gray-800 hover:bg-gray-200 w-full text-left"
                    onClick={() => handleSelectPackage("youtube")}
                  >
                    View YouTube Packages
                  </button>
                </div>
              )}
            </div>
          ) : null}

          <>
            <div className=" md:flex md:space-y-0 space-y-[10px] gap-[10px] 8k:w-[100%] 8k:space-x-0 4k:w-[100%] ">
              <div className="flex flex-col md:flex-row items-center md:w-[90%] bg-white rounded-lg shadow-md hover:shadow-lg p-6 gap-6">
                {/* Left Section: Image - Fixed size container */}
                <div className="w-[200px] flex-shrink-0">
                  <div className="rounded-full p-0.5 bg-black w-[200px] h-[200px]">
                    <div className="rounded-full p-0.5 bg-white h-full w-full">
                      <img
                        className="rounded-full w-full h-full object-cover"
                        src={image}
                        alt="profile"
                        loading="lazy"
                        fetchpriority="high"
                      />
                    </div>
                  </div>
                </div>

                {/* Right Section: Profile Details */}
                <div className="flex flex-col flex-1 space-y-6">
                  {/* Profile Name and Button */}
                  <div className="flex justify-between items-center">
                    <div className="relative group">
                      <h1
                        className="text-xl md:text-2xl font-extrabold text-gray-800 truncate"
                        title={igUserName.length > 16 ? igUserName : ""}
                        style={{ maxWidth: "16ch" }}
                      >
                        {igUserName}
                        {igUserName.length > 16 && "..."}
                      </h1>
                      {igUserName.length > 16 && (
                        <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded-md px-2 py-1 shadow-md top-full left-1/2 transform -translate-x-1/2 mt-1">
                          {igUserName}
                        </div>
                      )}
                    </div>

                    <button
                      onClick={() => {
                        const profileUrl =
                          platform === "instagram"
                            ? `https://www.instagram.com/${igUserName}`
                            : `https://www.youtube.com/${igUserName}`;
                        window.open(profileUrl, "_blank");
                      }}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <FaExternalLinkAlt />
                    </button>
                  </div>

                  {/* Categories */}
                  <div className="flex flex-wrap gap-3">
                    {categories.split(",").map((category, index) => (
                      <div
                        key={index}
                        className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full shadow-md text-sm font-medium"
                      >
                        {category.trim()}
                      </div>
                    ))}
                  </div>

                  {/* Ratings */}
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      {Array.from(
                        { length: Math.round(details?.overall_ratings || 0) },
                        (_, i) => (
                          <img
                            key={i}
                            className="w-5 h-5"
                            src="/Images/star.png"
                            alt="star"
                            loading="lazy"
                            fetchpriority="high"
                          />
                        )
                      )}
                      {details?.overall_ratings === 0 && (
                        <span className="text-gray-500 text-sm">
                          No rating yet
                        </span>
                      )}
                    </div>
                    <img
                      className="w-6 h-6"
                      src={
                        platform === "youtube"
                          ? "/Images/youtube.png"
                          : "/Images/insta.png"
                      }
                      alt="platform-icon"
                      loading="lazy"
                      fetchpriority="high"
                    />
                  </div>

                  {/* Metrics Grid */}
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
                    <div>
                      <h3 className="text-gray-800 font-bold text-sm">
                        Followers
                      </h3>
                      <p className="text-gray-600 text-sm">
                        {details?.followers || "N/A"}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-gray-800 font-bold text-sm">
                        Engagement Rate
                      </h3>
                      <p className="text-gray-600 text-sm">
                        {engagement_rate?.toFixed(2) || "N/A"}%
                      </p>
                    </div>
                    <div>
                      <h3 className="text-gray-800 font-bold text-sm">
                        Total Views
                      </h3>
                      <p className="text-gray-600 text-sm">
                        {details?.total_views || "N/A"}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-gray-800 font-bold text-sm">
                        {platform === "instagram" ? "Total Posts" : "Total Videos"}
                      </h3>
                      <p className="text-gray-600 text-sm">
                        {details?.total_posts || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* second right section  */}
              {popularPackage?.map((item) => (
                <div className=" bg-white md:w-1/2 p-[20px]  rounded-lg shadow-md hover:shadow-lg">
                  <div className="text-bold border border-x-0 border-t-0 pb-[10px] 8k:text-3xl 8k:font-bold">
                    Listing Overview
                  </div>
                  <div className="flex border border-x-0 border-t-0 text-[#495057] space-y-[10px] flex-col pt-[20px] pb-5">
                    <div className="flex justify-between 8k:text-xl 4k:text-[1vw]">
                      <div className="flex flex-col space-y-[5px]">
                        <div>DEAL</div>
                        <div className="font-bold text-black text-sm">
                          {item?.name}
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row text-sm">
                            <img
                              className="object-scale-down h-5"
                              src="/Images/cali.png"
                              alt="calendar"
                              height="2px"
                              loading="lazy"
                              fetchpriority="high"
                            ></img>
                            <pre> </pre>
                            {
                              item?.deliverables?.find(
                                (item) => item.delivery_time !== undefined
                              )?.delivery_time
                            }{" "}
                            days Delivery Time
                          </div>
                          <div className="flex flex-row text-sm">
                            <img
                              className="object-scale-down h-5"
                              src="/Images/C_vector.png"
                              alt="calendar"
                              height="2px"
                            ></img>
                            <pre> </pre>Content Rights
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="flex flex-row text-sm">
                            <img
                              className="object-scale-down h-5"
                              src="/Images/shield.png"
                              alt="calendar"
                              height="2px"
                            ></img>
                            <pre> </pre>Satisfaction Guaranteed
                          </div>
                        </div>
                      </div>
                      <div className="font-bold text-black pt-6">
                        Rs {item?.price}
                      </div>
                    </div>
                  </div>
                  <div className="flex text-[#495057] space-y-[10px] flex-col pt-[10px]">
                    <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
                      <div>Subtotal</div>
                      <div>Rs {item?.price}</div>
                    </div>
                    <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
                      <div>Total Delivery Time</div>
                      <div className="flex items-center">
                        <img
                          className="w-5 h-5 mr-2"
                          src="/Images/cali.png"
                          alt="calender"
                          loading="lazy"
                          fetchpriority="high"
                        />
                        <h1>
                          {
                            item?.deliverables?.find(
                              (item) => item.delivery_time !== undefined
                            )?.delivery_time
                          }{" "}
                          days
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#0b84ca] text-center cursor-pointer my-[20px] rounded-lg  text-white 8k:text-2xl 8k:mt-9">
                    <Popup
                      trigger={
                        <button className="button w-full h-full p-[10px]">
                          Get Influencer
                        </button>
                      }
                      modal
                      nested
                      closeOnDocumentClick={false}
                    >
                      {(close) => (
                        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-blue-600 bg-opacity-10">
                          <div className="modal-content relative bg-white p-4 rounded-lg shadow-lg w-96">
                            <button
                              className="close absolute top-0 right-0 -mt-4 -mr-4 w-10 h-10 bg-white rounded-full p-2 sm:w-10 sm:h-10 md:w-12 md:h-12"
                              onClick={close}
                            >
                              &times;
                            </button>
                            <div className="header text-xl font-semibold mb-4 text-silver-600">
                              Listing Overview
                            </div>
                            <hr className="my-4 border-t-2 border-gray-500" />

                            <div className="content text-[#495057] text-md font-normal">
                              <div className="mb-4">
                                <div className="flex flex-row justify-between mb-4">
                                  <div className="flex flex-row text-sm">
                                    <pre> </pre>
                                    <span className="font-bold text-black">
                                      Deal
                                    </span>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between mb-4">
                                  <div className="flex flex-row text-sm">
                                    <pre> </pre>
                                    <span className="font-bold text-silver-600">
                                      Package Name: {item?.name}
                                    </span>
                                  </div>
                                  <div className="flex flex-row text-sm">
                                    <span className="font-bold text-red-500">
                                      Rs {item?.price}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <img
                                    className="object-scale-down h-5"
                                    src="/Images/cali.png"
                                    alt="calendar"
                                    height="2px"
                                  ></img>
                                  <pre> </pre>
                                  {item?.deliverables?.map((item, index) => (
                                    <span key={index}>
                                      {item?.delivery_time} Delivery Time
                                    </span>
                                  ))}
                                </div>
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>Content Rights
                                </div>
                              </div>
                              <div className="flex flex-row mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>Satisfaction Guaranteed
                                </div>
                              </div>
                              <hr className="my-4 border-t-2 border-gray-500" />
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-gray-600">
                                    Subtotal
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="font-bold text-black">
                                    Rs {item?.price}
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-silver-600">
                                    Total Delivery Time
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <div className="flex items-center">
                                    <h1 className="font-bold text-gray-600">
                                      {
                                        item?.deliverables?.find(
                                          (item) =>
                                            item.delivery_time !== undefined
                                        )?.delivery_time
                                      }{" "}
                                      days
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-black">
                                    Name
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="font-bold text-silver-600">
                                    {igUserName}
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-gray-600">
                                    Platform
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="text-black">
                                    <img
                                      src={`${platform === "youtube"
                                          ? "/Images/youtube.png"
                                          : "/Images/insta.png"
                                        }`}
                                      alt="insta"
                                      className="w-5 h-5"
                                    />
                                    {/* {platform} */}
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold">Revisions</span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="text-silver-600">
                                    {item?.revisions || 0}
                                  </span>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-silver-600">
                                    Tax
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="text-gray-600">
                                    Rs{item?.taxPrice || 0}
                                  </span>
                                  <span className="text-red-500 font-medium">
                                    ({item?.gstPercentage || 0}% gst)
                                  </span>
                                </div>
                              </div>
                              {/* <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-row text-sm">
                                  <pre> </pre>
                                  <span className="font-bold text-silver-600">
                                    Total Payable
                                  </span>
                                </div>
                                <div className="flex flex-row text-sm">
                                  <span className="text-gray-600">
                                    {item?.totalPayable || 0}
                                  </span>
                                </div>
                              </div> */}
                            </div>
                            <div
                              className="bg-[#00A3FF] text-center cursor-pointer rounded-lg"
                              onClick={() => {
                                getinfluencers(
                                  "popular",
                                  item?.name,
                                  item?.price,
                                  item?._id
                                );
                              }}
                            >
                              <button
                                className="text-white text-sm lg:text-base py-2 px-4 lg:px-4"
                              // onClick={() => {
                              //   getinfluencers(
                              //     "popular",
                              //     item?.name,
                              //     item?.price,
                              //     item?._id
                              //   );
                              // }}
                              >
                                Get Influencer
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </div>
                  {/* <p className="lgk:text-[0.1vw] text-xs">
                    <span className="font-bold">
                      You won't be charged until after you click{" "}
                      <span className="text-blue-500 text-xs">
                        'Get Influencer'
                      </span>{" "}
                      .
                    </span>
                    <span className="text-[#6C757D] text-xs">
                      If the seller doesn't respond in 7 days, the order will be
                      automatically cancelled and your payment will be refunded.
                    </span>
                  </p> */}
                </div>
              ))}
            </div>

            {/* choose you package starts */}
            <div className="py-16">
              <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-left text-gray-900 mb-12">
                  Choose Your Package
                </h1>
                {details?.packages?.length === 0 ? (
                  <div className="text-left">
                    <h1 className="text-2xl font-semibold text-gray-400 ">
                      No Packages Available
                    </h1>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    {details?.packages?.map((item, index) => (
                      <div
                        key={item._id}
                        className={`bg-white rounded-2xl shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 ${index === Math.floor(details.packages.length / 2) ? "border-t-4 border-blue-500" : ""
                          }`}
                      >
                        <div className="p-8">
                          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                            {item.name}
                          </h2>
                          <p className="text-4xl font-bold text-gray-900 mb-6">
                            Rs {item.price}
                          </p>
                          {index === Math.floor(details.packages.length / 2) && (
                            <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-full">
                              Popular
                            </span>
                          )}
                        </div>
                        <div className="px-8 pb-8">
                          {Object.entries(item.deliverables[0]).map(([key, value]) =>
                            value === "true" ? (
                              <div key={key} className="flex items-center mb-4">
                                <svg
                                  className="w-5 h-5 text-green-500 mr-3"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  ></path>
                                </svg>
                                <span className="text-gray-700">
                                  {key
                                    .replace("_", " ")
                                    .replace("ig", "Instagram")
                                    .replace("yt", "YouTube")}
                                </span>
                              </div>
                            ) : null
                          )}
                          {/* Display new fields */}
                          {item?.revisions > 0 && (
                            <div className="flex items-center mb-4">
                              <svg
                                className="w-5 h-5 text-green-500 mr-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                              <span className="text-gray-700">Revisions: {item.revisions}</span>
                            </div>
                          )}
                          {item?.content_creation && (
                            <div className="flex items-center mb-4">
                              <svg
                                className="w-5 h-5 text-green-500 mr-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                              <span className="text-gray-700">Content Creation Included</span>
                            </div>
                          )}
                          {item?.mentions && (
                            <div className="flex items-center mb-4">
                              <svg
                                className="w-5 h-5 text-green-500 mr-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                              <span className="text-gray-700">Mentions Included</span>
                            </div>
                          )}
                          {item?.link_in_bio && (
                            <div className="flex items-center mb-4">
                              <svg
                                className="w-5 h-5 text-green-500 mr-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                              <span className="text-gray-700">Link in Bio</span>
                            </div>
                          )}
                        </div>
                        <div className="px-8 pb-8">
                          <button
                            onClick={() =>
                              getinfluencers(
                                index === Math.floor(details.packages.length / 2)
                                  ? "popular"
                                  : "notpopular",
                                item.name,
                                item.price,
                                item._id
                              )
                            }
                            className="w-full bg-blue-500 text-white rounded-lg py-3 font-semibold hover:bg-blue-600 transition duration-300"
                          >
                            Get Influencer
                          </button>
                        </div>
                      </div>
                    ))}

                  </div>
                )}
              </div>
            </div>
            {/* choose you package ends */}

            {/* review */}
            <div className="my-4 p-4 bg-gray-100 border border-gray-300 rounded-md shadow-md">
              <h1 className="text-2xl pb-1 text-gray-800 font-semibold">
                Reviews
              </h1>
              {influencerReview && influencerReview.length > 0 ? (
                influencerReview.map((reviewData, index) => (
                  <div
                    key={index}
                    className="my-4 p-4 bg-white rounded-md border border-gray-200 shadow-sm"
                  >
                    {reviewData?.reviews.map((review, idx) => (
                      <div key={idx} className="mb-4">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src="./Images/default_cloutin_img.webp"
                              alt="Avatar"
                              loading="lazy"
                              fetchpriority="high"
                            />
                          </div>
                          <h1 className="text-lg font-medium text-gray-800">
                            {review?.userName}
                          </h1>
                        </div>
                        <div className="mb-2">
                          {[...Array(5)].map((_, i) => (
                            <span key={i}>
                              {review?.rating >= i + 1 ? (
                                <StarIcon
                                  style={{ color: "#ffa41c", fontSize: "20px" }}
                                />
                              ) : review.rating >= i + 0.5 ? (
                                <StarHalfIcon
                                  style={{ color: "#ffa41c", fontSize: "20px" }}
                                />
                              ) : (
                                <StarIcon
                                  style={{ color: "gray", fontSize: "20px" }}
                                />
                              )}
                            </span>
                          ))}
                        </div>
                        <div className="mb-2">
                          <h1 className="text-sm text-gray-500">
                            Posted on: {review?.createdAt?.split("T")[0]}
                          </h1>
                        </div>
                        <div className="mb-2 text-gray-700">
                          <p className="break-words text-ellipsis">
                            {expandedReviews[idx]
                              ? review?.review
                              : `${review?.review.substring(0, 150)}...`}
                            {review?.review.length > 150 && (
                              <button
                                onClick={() => toggleReadMore(idx)}
                                className="text-blue-500 ml-1 underline"
                              >
                                {expandedReviews[idx]
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div className="border border-gray-400 px-4 py-6 bg-white rounded-md shadow-sm text-center">
                  <h1 className="text-gray-600">No Reviews Yet!</h1>
                </div>
              )}
            </div>
          </>

          {/* review section ends */}
        </div>
      )}
    </>
  );
}

export default SinglePage;
