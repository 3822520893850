import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";
// import Cookies from "js-cookie";
import { SharedStateContext } from "../context/SharedStateContext";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashBoardData,
  fetchGraphData,
} from "../redux/Slice/dashboardSlice";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import LineChartReact from "./Campaign/LineChartReact";
import MainDashCard from "./MainDashCard";
import { TbDatabaseOff } from "react-icons/tb";
import "./main.css";
import PaginationComponent from "../utils/PaginationCompoent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NetworkStatusOverlay from "../Pages/NetworkStatusOverlay";
import { formateDate } from "../utils/helperFunction";
import ErrorPage from "../utils/ErrorPage";
import ApiInterceptor from "../utils/ApiInterceptor";
import { config } from "../config";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Main({ handleMarketPlace }) {
  const [opt1, setOpt1] = useState(true);
  const [opt2, setOpt2] = useState(true);
  const [opt3, setOpt3] = useState(true);
  const [opt4, setOpt4] = useState(false);
  const [opt5, setOpt5] = useState(false);
  const [option, setOption] = useState(false);
  const [opts1, setOpts1] = useState(false);
  const [opts2, setOpts2] = useState(false);
  const [opts3, setOpts3] = useState(false);
  const [opts4, setOpts4] = useState(false);
  const [opts5, setOpts5] = useState(false);
  const [option2, setOption2] = useState(false);
  const [oopts1, setOopts1] = useState(false);
  const [oopts2, setOopts2] = useState(false);
  const [oopts3, setOopts3] = useState(false);
  const [oopts4, setOopts4] = useState(false);
  const [oopts5, setOopts5] = useState(false);
  const navigate = useNavigate();
  const [option1, setOption1] = useState(false);
  const [active, setActive] = useState([]);
  const [finished, setFinished] = useState([]);
  const [influencerData, setInfluencerData] = useState([]);
  const [AllCamp, setAllCamp] = useState([]);
  const [brands, setBrands] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [allAffiliates, setAllAffiliates] = useState([]);
  const companyId = Cookies.get("userid");

  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [count, setCount] = useState();
  const dispatch = useDispatch();
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);

  const [loadingAcStatus, setLoadingAcStatus] = useState(false);

  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      setLoadingAcStatus(true);
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
      setLoadingAcStatus(false);
    }
  }, [dispatch]);

  const [openDisablePageModal, setOpenDisablePageModal] = useState(false);
  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  useEffect(() => {
    const fetchConversions = async () => {
      try {
        const response = await ApiInterceptor.get(
          `influencerAnalytics/getcampaigndetails`
        );
        setCount(response?.data?.stats);
      } catch (error) {
        setError(true);
      }
    };

    fetchConversions();

    const fetchData = async () => {
      try {
        // Make the API request using Axios
        const response = await ApiInterceptor.get(
          `affiliate/affiliateCampaignDetails/${companyId}`
        );
      } catch (error) {
        // Handle errors
        setError(true);
      } finally {
        // Set loading to false once the request is completed
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  const [dashboardData, setDashboardData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const { data, graphDataDB } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashBoardData({ company_id: companyId }));
  }, []);
  useEffect(() => {
    setDashboardData(data);
  }, [dispatch, data]);
  useEffect(() => {
    dispatch(fetchGraphData({ company_id: companyId }));
  }, []);
  useEffect(() => {
    setGraphData(graphDataDB);
  }, [dispatch, graphDataDB]);

  const [loadingTableData, setLoadingTableData] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [currentPage4, setCurrentPage4] = useState(1);
  const [currentPage5, setCurrentPage5] = useState(1);
  const rowsPerPage = 15;

  const [currAffiliateData, setcurrAffiliateData] = useState([]);
  const [currActiveData, setcurrActiveData] = useState([]);
  const [currFinishedData, setcurrFinishedData] = useState([]);
  const [currBrandAwarenessData, setcurrBrandAwarenessData] = useState([]);
  const [currAllCampData, setcurrAllCampData] = useState([]);

  useEffect(() => {
    ApiInterceptor.get(`campaign/company/${companyId}`)
      .then((response) => {
        if (response?.status === 200) {
          setActive(response.data.active);
          setFinished(response.data.finished);
          setAllCamp(response.data.allCampaigns);
          setBrands(response.data.brands);
          setInfluencerData(response.data.Influencers);
          setDataArray(response.data.affiliateCampaigns);

          setcurrAffiliateData(
            response.data.affiliateCampaigns.slice(
              (currentPage - 1) * rowsPerPage,
              currentPage * rowsPerPage
            )
          );

          setcurrActiveData(
            response.data.active.slice(
              (currentPage2 - 1) * rowsPerPage,
              currentPage2 * rowsPerPage
            )
          );
          setcurrFinishedData(
            response.data.finished.slice(
              (currentPage3 - 1) * rowsPerPage,
              currentPage3 * rowsPerPage
            )
          );

          setcurrBrandAwarenessData(
            response.data.brands.slice(
              (currentPage4 - 1) * rowsPerPage,
              currentPage4 * rowsPerPage
            )
          );

          setcurrAllCampData(
            response.data.allCampaigns.slice(
              (currentPage5 - 1) * rowsPerPage,
              currentPage5 * rowsPerPage
            )
          );

          setLoadingTableData(false);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
      });

    const get = async () => {
      try {
        const data2 = await ApiInterceptor.get("individualInfluencer?fields=", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const data4 = await ApiInterceptor.get("influencerAnalytics?fields=", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const data5 = await ApiInterceptor.get("influencer?fields=", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const data3 = await ApiInterceptor.get("campaign?fields=", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const combinedData = {};

        data5.data.influencer.forEach((item) => {
          combinedData[item._id] = {
            ...combinedData[item._id],
            influencer: item,
          };
        });

        data2.data.influencer.forEach((item) => {
          global.individualInfluencerId = item.influencer_id;
          combinedData[item.influencer_id] = {
            ...combinedData[item.influencer_id],
            individualInfluencer: item,
          };
        });

        const infAnId =
          combinedData[global.individualInfluencerId]?.individualInfluencer
            .influencer_analytics_id;
        data4.data.influencer
          .filter((item) => item.influencer_analytics_id === infAnId)
          .forEach((item) => {
            combinedData[global.individualInfluencerId] = {
              ...combinedData[global.individualInfluencerId],
              influencerAnalytics: item,
            };
          });

        const combinedDataFinal = Object.values(combinedData).filter((item) => {
          return (
            item.individualInfluencer &&
            item.influencer &&
            item.influencerAnalytics &&
            data2.data.influencer.some(
              (dataItem) =>
                dataItem.influencer_id ===
                item.individualInfluencer.influencer_id
            ) &&
            data4.data.influencer.some(
              (dataItem) =>
                dataItem.influencer_analytics_id ===
                item.individualInfluencer.influencer_analytics_id
            ) &&
            data5.data.influencer.some(
              (dataItem) => dataItem._id === item.influencer._id
            )
          );
        });

        setInfluencer(combinedDataFinal);

        // Set loadingTableData to false after successful data fetching
        // setLoadingTableData(false);
      } catch (error) {
        setError(true);
      }
    };
    get();
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getStatusColor = (status) => {
    const statusColors = {
      active: "text-[#3D9F00]",
      pending: "text-[#00ADB5]",
      inactive: "text-[#ff0000]",
      approved: "text-[#3D9F00]",
      rejected: "text-[#FF0000]",
      disapproved: "text-[#ff0000]"
    };
    return statusColors[status.toLowerCase()] || "text-[#00598C]";
  };

  const [graphD, setGraphD] = useState([]);
  const [monthG, setMonthG] = useState(new Date().getMonth() + 1);
  const [yearG, setYearG] = useState(new Date().getFullYear());
  const [graphLoading, setGraphLoading] = useState(true);
  const fetchGData = async () => {
    try {
      // let url = `https://api.cloutin.co/api/spendgraph/${companyId}`;
      let url = `${config.REACT_APP_BACKEND_SERVICE_URL}spendgraph/${companyId}`;

      if (yearG && monthG) {
        url += `?year=${yearG}&month=${monthG}`;
      } else if (yearG) {
        url += `?year=${yearG}`;
      } else if (monthG) {
        url += `?month=${monthG}`;
      }
      const res = await axios.get(url);
      setGraphD(res?.data);
    } catch (error) {
      setGraphD([]);
      setError(true);

      // toast.error("Error in loading graph data", {
      //   position: "top-center",
      //   duration: 1500,
      // });
    } finally {
      setError(false);
      setGraphLoading(false);
    }
  };
  useEffect(() => {
    fetchGData();
  }, [monthG, yearG]);

  const LoadingAnimation = () => (
    <div className="p-4 rounded-lg h-[300px] flex items-center justify-center">
      <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900"></div>
    </div>
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setcurrAffiliateData(
      dataArray.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };
  const handlePageChange2 = (page) => {
    setCurrentPage2(page);
    setcurrActiveData(
      active.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };
  const handlePageChange3 = (page) => {
    setCurrentPage3(page);
    setcurrFinishedData(
      finished.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };
  const handlePageChange4 = (page) => {
    setCurrentPage4(page);
    setcurrBrandAwarenessData(
      brands.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };
  const handlePageChange5 = (page) => {
    setCurrentPage5(page);
    setcurrAllCampData(
      AllCamp.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };

  const [sortType, setSortType] = useState("asc");

  const [selectIcon, setSelectIcon] = useState([]);

  const handleSort = (field) => {
    if (field) setSelectIcon([field, "aff"]);
    if (field === "date") {
      if (sortType === "asc") {
        dataArray.sort((a, b) => new Date(b[field]) - new Date(a[field]));
        handlePageChange(currentPage);
        setSortType("desc");
      } else {
        dataArray.sort((a, b) => new Date(a[field]) - new Date(b[field]));
        handlePageChange(currentPage);
        setSortType("asc");
      }
    } else if (field === "influencersApplied") {
      if (sortType === "asc") {
        dataArray.sort(
          (a, b) => b.influencersApplied?.length - a.influencersApplied?.length
        );
        handlePageChange(currentPage);
        setSortType("desc");
      } else {
        dataArray.sort(
          (a, b) => a.influencersApplied?.length - b.influencersApplied?.length
        );
        handlePageChange(currentPage);
        setSortType("asc");
      }
    }

    else if (field === "rejected") {
      if (sortType === "asc") {
        dataArray.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 2) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 2) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange(currentPage);
        setSortType("desc");
      } else {
        dataArray.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 2) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 2) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange(currentPage);
        setSortType("asc");
      }
    } else if (field === "posted") {
      if (sortType === "asc") {
        dataArray.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 1) return acc + 1;
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 1) return acc + 1;
              return acc;
            }, 0)
        );
        handlePageChange(currentPage);
        setSortType("desc");
      } else {
        dataArray.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 1) return acc + 1;
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === 1) return acc + 1;
              return acc;
            }, 0)
        );
        handlePageChange(currentPage);
        setSortType("asc");
      }
    }


    else {
      if (sortType === "asc") {
        dataArray.sort((a, b) => b[field] - a[field]);
        handlePageChange(currentPage);
        setSortType("desc");
      } else {
        dataArray.sort((a, b) => a[field] - b[field]);
        handlePageChange(currentPage);
        setSortType("asc");
      }
    }
  };

  const handleSort2 = (field) => {
    if (field === "createdAt") {
      if (sortType === "asc") {
        active.sort((a, b) => new Date(b[field]) - new Date(a[field]));
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort((a, b) => new Date(a[field]) - new Date(b[field]));
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "influencersApplied") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "rejected") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "posted") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) return acc + 1;
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) return acc + 1;
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) return acc + 1;
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) return acc + 1;
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "clicks") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "followers") {
      if (sortType === "asc") {
        active.sort((a, b) => b?.campaignFollowers - a?.campaignFollowers);
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort((a, b) => a?.campaignFollowers - b?.campaignFollowers);
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    } else if (field === "conversions") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    }




    else if (field === "addtocarts") {
      if (sortType === "asc") {
        active.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    }

    else {
      if (sortType === "asc") {
        active.sort((a, b) => b[field] - a[field]);
        handlePageChange2(currentPage2);
        setSortType("desc");
      } else {
        active.sort((a, b) => a[field] - b[field]);
        handlePageChange2(currentPage2);
        setSortType("asc");
      }
    }

  };

  const handleSort3 = (field) => {
    if (field === "createdAt") {
      if (sortType === "asc") {
        finished.sort((a, b) => new Date(b[field]) - new Date(a[field]));
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort((a, b) => new Date(a[field]) - new Date(b[field]));
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "InfluencersRequired") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "posted") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "rejected") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "clicks") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "followers") {
      if (sortType === "asc") {
        finished.sort((a, b) => b?.campaignFollowers - a?.campaignFollowers);
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort((a, b) => a?.campaignFollowers - b?.campaignFollowers);
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    } else if (field === "conversions") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    }




    else if (field === "addtocarts") {
      if (sortType === "asc") {
        finished.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    }

    else {
      if (sortType === "asc") {
        finished.sort((a, b) => b[field] - a[field]);
        handlePageChange3(currentPage3);
        setSortType("desc");
      } else {
        finished.sort((a, b) => a[field] - b[field]);
        handlePageChange3(currentPage3);
        setSortType("asc");
      }
    }


  };

  const handleSort4 = (field) => {
    if (sortType === "asc") {
      influencerData.sort((a, b) => b[field] - a[field]);
      setSortType("desc");
    } else {
      influencerData.sort((a, b) => a[field] - b[field]);
      setSortType("asc");
    }
  };

  const handleSort5 = (field) => {
    if (field === "createdAt") {
      if (sortType === "asc") {
        brands.sort((a, b) => new Date(b[field]) - new Date(a[field]));
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort((a, b) => new Date(a[field]) - new Date(b[field]));
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    } else if (field === "InfluencersRequired") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    } else if (field === "posted") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    } else if (field === "rejected") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    } else if (field === "clicks") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    } else if (field === "followers") {
      if (sortType === "asc") {
        brands.sort((a, b) => b?.campaignFollowers - a?.campaignFollowers);
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort((a, b) => a?.campaignFollowers - b?.campaignFollowers);
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    }


    else if (field === "conversions") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    }


    else if (field === "addtocarts") {
      if (sortType === "asc") {
        brands.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    }

    else {
      if (sortType === "asc") {
        brands.sort((a, b) => b[field] - a[field]);
        handlePageChange4(currentPage4);
        setSortType("desc");
      } else {
        brands.sort((a, b) => a[field] - b[field]);
        handlePageChange4(currentPage4);
        setSortType("asc");
      }
    }


  };

  const handleSort6 = (field) => {
    if (field === "createdAt") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => new Date(b[field]) - new Date(a[field]));
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => new Date(a[field]) - new Date(b[field]));
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "InfluencersRequired") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Approved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "campaignEngagement") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => b?.campaignEngagement - a?.campaignEngagement);
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => a?.campaignEngagement - b?.campaignEngagement);
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "followers") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => b?.campaignFollowers - a?.campaignFollowers);
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => a?.campaignFollowers - b?.campaignFollowers);
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "clicks") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === a?._id) {
                return acc + hits;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, hits }) => {
              if (campaign_id === b?._id) {
                return acc + hits;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "likes") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => b?.campaignLikes - a?.campaignLikes);
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => a?.campaignLikes - b?.campaignLikes);
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "comments") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => b?.campaignComments - a?.campaignComments);
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => a?.campaignComments - b?.campaignComments);
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "purchases") {
      if (sortType === "asc") {
        AllCamp.sort((a, b) => b?.campaignPurchases - a?.campaignPurchases);
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort((a, b) => a?.campaignPurchases - b?.campaignPurchases);
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    }


    else if (field === "conversions") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === a?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            ) -
            count?.reduce(
              (acc, { campaign_id, add_to_carts, hits, no_of_purchases }) => {
                if (campaign_id === b?._id)
                  return acc + hits + no_of_purchases + add_to_carts;
                return acc;
              },
              0
            )
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    }


    else if (field === "addtocarts") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === a?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0) -
            count?.reduce((acc, { campaign_id, add_to_carts }) => {
              if (campaign_id === b?._id) {
                return acc + add_to_carts;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    }



    else if (field === "posted") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status, post_url }) => {
              if (status === "Approved" && post_url) {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    } else if (field === "rejected") {
      if (sortType === "asc") {
        AllCamp.sort(
          (a, b) =>
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("desc");
      } else {
        AllCamp.sort(
          (a, b) =>
            a?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0) -
            b?.applicationStatusTimeline.reduce((acc, { status }) => {
              if (status === "Disapproved") {
                return acc + 1;
              }
              return acc;
            }, 0)
        );
        handlePageChange5(currentPage5);
        setSortType("asc");
      }
    }


  };

  if (error) {
    return <ErrorPage />;
  }
  if (userAccountStatus === true) {
    return <OverlayDisableAccountPage isOpen={true} />;
  }

  return (
    <div className="pt-[20px] 3xl:text-2xl main-container">
      <div className="main-top">
        <div className="flex flex-col lg:flex-row main-upper">
          {/* Left Part */}
          <div
            className={`main-card grid lg:w-[40%] p-4 grid-cols-1 lg:grid-cols-2 gap-4
          ${graphLoading || graphD?.length === 0
                ? "lg:min-w-[40%]"
                : "lg:w-[40%]"
              }  
            `}
          >
            {/* First Row of Cards */}
            <MainDashCard
              titleOfCard={"Total Followers Reach"}
              dataNumber={dashboardData?.map((item) => item.total_followers)}
              incre_decre_data={dashboardData?.map((item) =>
                item.followersIncreasePercentage === "NaN" ||
                  item.followersIncreasePercentage === null ||
                  item.followersIncreasePercentage === undefined
                  ? "0"
                  : item.followersIncreasePercentage
              )}
              iconImg={"/Images/followers.png"}
            />
            <MainDashCard
              titleOfCard={"Sales Increase Weekly"}
              dataNumber={
                dashboardData &&
                dashboardData.map((item) => item?.currentWeekSales)
              }
              incre_decre_data={dashboardData?.map((item) =>
                item.salesPercentageWeekly < 0
                  ? item.salesPercentageWeekly * -1
                  : item.salesPercentageWeekly
              )}
              iconImg={"/Images/sales.png"}
            />
            {/* Second Row of Cards */}
            <MainDashCard
              titleOfCard={"Total Influencers"}
              dataNumber={dashboardData?.map((item) => item.total_influencers)}
              incre_decre_data={dashboardData?.map((item) =>
                item.influencersChangePercentage < 0
                  ? item.influencersChangePercentage * -1
                  : item.influencersChangePercentage
              )}
              iconImg={"/Images/influencer_main.png"}
            />
            <MainDashCard
              titleOfCard={"Return on Investments"}
              dataNumber={dashboardData?.map((item) =>
                item?.returnOnInvestment === "NaN"
                  ? "0"
                  : item?.returnOnInvestment
              )}
              incre_decre_data={dashboardData?.map((item) => {
                const percentageChange = isNaN(
                  item.returnOnInvestmentPercentageChange
                )
                  ? 0
                  : item.returnOnInvestmentPercentageChange < 0
                    ? item.returnOnInvestmentPercentageChange * -1
                    : item.returnOnInvestmentPercentageChange;
                return percentageChange;
              })}
              iconImg={"/Images/roi.png"}
            />
          </div>

          {/* graph -right part */}
          <div
            className={`lg:w-[60%] p-2 main-graph 
          ${graphLoading || graphD?.length === 0
                ? "lg:min-w-[60%]"
                : "lg:w-[60%]"
              }
            `}
          >
            <div className="flex flex-col items-start md:px-2 md:py-2 bg-white shadow-md rounded-lg">
              <div className="flex justify-between w-full items-center">
                <h1 className="text-xl font-semibold">Total Spend Over Time</h1>
                <div className="flex gap-2">
                  <label className="flex flex-col items-center">
                    <span className="mb-1 text-sm font-medium">Year</span>
                    <select
                      value={yearG}
                      onChange={(e) => setYearG(Number(e.target.value))}
                      className="p-1 border border-gray-300 rounded-md text-sm"
                    >
                      <option value="0">All</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                  </label>
                  <label className="flex flex-col items-center">
                    <span className="mb-1 text-sm font-medium">Month</span>
                    <select
                      value={monthG}
                      onChange={(e) => setMonthG(Number(e.target.value))}
                      className="p-1 border border-gray-300 rounded-md text-sm"
                    >
                      <option value="0">All</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="h-[100px] sm:h-[200px] lg:h-[300px] w-full max-w-4xl graph-display">
                {graphLoading ? (
                  <div className="w-full h-full flex items-center justify-center bg-white">
                    <LoadingAnimation />
                  </div>
                ) : graphD?.length === 0 ? (
                  <div className="h-full flex items-center justify-center">
                    <TbDatabaseOff className="text-gray-500 text-5xl mb-2" />
                    <h1 className="text-gray-500 text-2xl ml-2">No Data Found</h1>
                  </div>
                ) : (
                  <LineChartReact data={graphD} />
                )}
                {/* //  graphD?.length === 0 ? (
                //   <div className="p-4 rounded-lg h-[300px] flex items-center justify-center w-[100%]">
                //     <TbDatabaseOff className="text-gray-500 text-5xl mb-2" />
                //     <h1 className="text-gray-500 text-2xl ml-2">
                //       No Data Found
                //     </h1>
                //   </div>
                // ) : (
                //   <LineChartReact data={graphD} />
                // )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* table starts */}
      <div className="main-tables">
        <div>
          <Box sx={{ width: "100%", marginTop: "50px" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", color: "black" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "black", // Change this to your desired color
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    flexGrow: 1,
                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "white",
                    },
                  },
                }}
              >
                <Tab label="Affiliate Campaign" {...a11yProps(0)} />
                <Tab label="Active Campaign" {...a11yProps(1)} />
                <Tab label="Finished Campaign" {...a11yProps(2)} />
                <Tab label="Influencers" {...a11yProps(3)} />
                <Tab label="Brand Awarness Campaign" {...a11yProps(4)} />
                <Tab label="All Campaign" {...a11yProps(5)} />
              </Tabs>
            </Box>
            {/* affiliate campaign starts */}
            <CustomTabPanel value={value} index={0}>
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4 space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-semibold text-gray-800">
                  Affiliate Campaigns
                </h1>
                <div className="relative ml-auto">
                  {dataArray?.length > 0 && (
                    <button
                      className="w-8 h-8 p-1.5 text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300 transition-all duration-200 "
                      onClick={() => setOption2(!option2)}
                      aria-label="Toggle options"
                    >
                      <img
                        className="w-full h-full"
                        src="/Images/option.png"
                        alt=""
                      />
                    </button>
                  )}
                  {option2 && (
                    <div className="absolute right-0 top-full mt-2 bg-white shadow-lg rounded-lg overflow-hidden z-20">
                      <div className="p-2 flex flex-col sm:flex-row gap-2">
                        {[
                          {
                            state: oopts1,
                            setter: setOopts1,
                            label: "Total No. Of Likes",
                          },
                          {
                            state: oopts2,
                            setter: setOopts2,
                            label: "Total No. Of Comments",
                          },
                          {
                            state: oopts3,
                            setter: setOopts3,
                            label: "Total No. Of Purchases",
                          },
                        ].map((item, index) => (
                          <button
                            key={index}
                            className={`py-2 px-3 text-sm border rounded-md shadow-sm transition-all duration-200 hover:shadow-md  whitespace-nowrap ${item.state
                              ? "bg-[#D9F1FF] border-2 border-[#00A3FF] text-[#00A3FF] font-medium"
                              : "bg-white border-[#00A3FF] text-gray-700 hover:bg-blue-50"
                              }`}
                            onClick={() => item.setter(!item.state)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-lg overflow-hidden bg-white shadow-lg">
                <div className="w-full overflow-x-scroll">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200 font-[8px]">
                        <th className="px-4 py-2 text-left">Campaign Name</th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("date")}
                        >
                          <div>
                            Date
                            {selectIcon &&
                              selectIcon[0] === "date" &&
                              selectIcon[1] === "aff" &&
                              sortType !== "asc" ? (
                              <ArrowUpwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            ) : (
                              <ArrowDownwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            )}
                          </div>
                        </th>
                        <th className="px-4 py-2 text-left">Status</th>
                        <th
                          className="px-4 py-2 text-center cursor-pointer"
                          onClick={() => handleSort("influencersApplied")}
                        >
                          No. of Influencers
                          {selectIcon &&
                            selectIcon[0] === "influencersApplied" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("engagement")}
                        >
                          Engagement Rate
                          {selectIcon &&
                            selectIcon[0] === "engagement" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("followers")}
                        >
                          Total No. Of Followers
                          {selectIcon &&
                            selectIcon[0] === "followers" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("hits")}
                        >
                          Total No. Of Clicks
                          {selectIcon &&
                            selectIcon[0] === "hits" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("add_to_carts")}
                        >
                          Add to Carts
                          {selectIcon &&
                            selectIcon[0] === "add_to_carts" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("conversions")}
                        >
                          Conversions
                          {selectIcon &&
                            selectIcon[0] === "conversions" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("posted")}
                        >
                          Posted
                          {selectIcon &&
                            selectIcon[0] === "posted" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>
                        <th
                          className="px-4 py-2 cursor-pointer"
                          onClick={() => handleSort("rejected")}
                        >
                          Rejected
                          {selectIcon &&
                            selectIcon[0] === "rejected" &&
                            selectIcon[1] === "aff" &&
                            sortType !== "asc" ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              className=" mb-1"
                            />
                          )}
                        </th>

                        {oopts1 && (
                          <th
                            className="px-4 py-2 cursor-pointer"
                            onClick={() => handleSort("like")}
                          >
                            Total No. Of Likes
                            {selectIcon &&
                              selectIcon[0] === "like" &&
                              selectIcon[1] === "aff" &&
                              sortType !== "asc" ? (
                              <ArrowUpwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            ) : (
                              <ArrowDownwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            )}
                          </th>
                        )}
                        {oopts2 && (
                          <th
                            className="px-4 py-2 cursor-pointer"
                            onClick={() => handleSort("comment")}
                          >
                            Total No. Of Comments
                            {selectIcon &&
                              selectIcon[0] === "comment" &&
                              selectIcon[1] === "aff" &&
                              sortType !== "asc" ? (
                              <ArrowUpwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            ) : (
                              <ArrowDownwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            )}
                          </th>
                        )}
                        {oopts3 && (
                          <th
                            className="px-4 py-2 cursor-pointer"
                            onClick={() => handleSort("no_of_purchases")}
                          >
                            Total No. Of Purchases
                            {selectIcon &&
                              selectIcon[0] === "no_of_purchases" &&
                              selectIcon[1] === "aff" &&
                              sortType !== "asc" ? (
                              <ArrowUpwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            ) : (
                              <ArrowDownwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            )}
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {loadingTableData ? (
                        <div>
                          <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                            Loading Affiliate Data....
                          </h1>
                        </div>
                      ) : (
                        <>
                          {currAffiliateData?.length > 0 ? (
                            currAffiliateData.map((item) => (
                              <tr
                                key={item._id}
                                className="border-b hover:bg-gray-100 text-[#707070] text-center w-full"
                                style={{ fontWeight: 500 }}
                              >
                                <td className="px-4 py-2 text-left">
                                  {item.name}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {formateDate(item.date)}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  <span className={` text-sm font-semibold rounded-md ${getStatusColor(
                                    item?.status
                                  )}`}>
                                    {item?.status
                                      ? item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()
                                      : ''}
                                  </span>
                                </td>
                                <td className="px-4 py-2">
                                  {item.influencersApplied?.length}
                                </td>
                                {(item.engagement || item.engagement == 0) && (
                                  <td className="px-4 py-2">
                                    {item.engagement.toFixed(2)}%
                                  </td>
                                )}
                                <td className="px-4 py-2">{item?.followers}</td>
                                <td className="px-4 py-2">{item?.hits}</td>
                                <td className="px-4 py-2">{item?.add_to_carts}</td>
                                <td className="px-4 py-2">{item?.conversions}</td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length === 0
                                    ? 0
                                    : item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === 1)
                                          return acc + 1;
                                        return acc;
                                      },
                                      0
                                    )}
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === 2) {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>

                                {oopts1 && (
                                  <td className="px-4 py-2">{item.like}</td>
                                )}
                                {oopts2 && (
                                  <td className="px-4 py-2">{item.comment}</td>
                                )}
                                {oopts3 && (
                                  <td className="px-4 py-2">
                                    {item.no_of_purchases}
                                  </td>
                                )}
                                {oopts4 && <td className="px-4 py-2"></td>}
                                {oopts5 && <td className="px-4 py-2"></td>}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-2 text-center text-xl text-[#707070] font-[500] "
                              >
                                No Affiliate Campaigns Found !
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <PaginationComponent
                    currentPage={currentPage}
                    itemsPerPage={rowsPerPage}
                    totalItems={dataArray.length}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>

              {/* Affiliate Campaign table ends */}
            </CustomTabPanel>

            {/* affiliate campaign ends */}

            {/* active campaigns starts */}

            <CustomTabPanel value={value} index={1}>
              {/* active */}

              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4 space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-semibold text-gray-800">
                  Active Campaigns
                </h1>
                <div className="flex items-center space-x-4">
                  {option1 && (
                    <div className="bg-white shadow-md rounded-lg p-2 grid grid-cols-2 sm:grid-cols-4 gap-2">
                      {[
                        { state: opts1, setter: setOpts1, label: "Rejeted" },
                        { state: opts2, setter: setOpts2, label: "Clicks" },
                        {
                          state: opts3,
                          setter: setOpts3,
                          label: "Total Followers",
                        },
                        {
                          state: opts4,
                          setter: setOpts4,
                          label: "Conversions",
                        },
                      ].map((item, index) => (
                        <button
                          key={index}
                          className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md transition-colors duration-200 shadow- ${item.state ? "bg-[#D9F1FF]" : "bg-white"
                            }`}
                          onClick={() => item.setter(!item.state)}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  )}
                  {active?.length > 0 && (
                    <button
                      className="w-8 h-8 p-1.5 text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
                      onClick={() => setOption1(!option1)}
                    >
                      <img
                        className="w-full h-full"
                        src="/Images/option.png"
                        alt="options"
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className="rounded-lg overflow-hidden bg-white shadow-lg">
                <div className="w-full overflow-x-scroll">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="px-4 py-2 text-center">Campaign Name</th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("createdAt")}
                        >
                          Date
                        </th>
                        <th className="px-4 py-2">Status</th>
                        <th
                          className="px-4 py-2 text-center"
                          onClick={() => handleSort2("influencersApplied")}
                        >
                          No. of Influencers
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("posted")}
                        >
                          Posted
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("addtocarts")}
                        >
                          Add to Carts
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("campaignPurchases")}
                        >
                          Purchases
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("campaignlikes")}
                        >
                          Likes
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("campaigncomments")}
                        >
                          Comments
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort2("campaignEngagement")}
                        >
                          Engagement Rate
                        </th>
                        {opts1 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort2("rejected")}
                          >
                            Rejected
                          </th>
                        )}
                        {opts2 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort2("clicks")}
                          >
                            Clicks
                          </th>
                        )}
                        {opts3 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort2("followers")}
                          >
                            Total Followers
                          </th>
                        )}
                        {opts4 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort2("conversions")}
                          >
                            Conversions
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {loadingTableData ? (
                        <div>
                          <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                            Loading Acitve Campaigns Data....
                          </h1>
                        </div>
                      ) : (
                        <>
                          {currActiveData && currActiveData.length > 0 ? (
                            currActiveData?.map((item) => (
                              <tr
                                key={item._id}
                                className="border-b hover:bg-gray-100 text-center text-[#707070] font-[500]"
                              >
                                <td className="px-4 py-2 text-center">
                                  {item?.name}
                                </td>
                                <td className="px-4 py-2">
                                  {formateDate(item.createdAt)}
                                </td>
                                <td className="px-4 py-2">
                                  <span
                                    className={`px-2 py-1 text-sm font-semibold rounded-md ${getStatusColor(
                                      item?.statusCampaign
                                    )}`}
                                  >
                                    {item?.statusCampaign}
                                  </span>
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === "Approved") {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length === 0
                                    ? 0
                                    : item.applicationStatusTimeline.reduce(
                                      (acc, { status, post_url }) => {
                                        if (status === "Approved" && post_url)
                                          return acc + 1;
                                        return acc;
                                      },
                                      0
                                    )}
                                </td>
                                <td className="px-4 py-2">
                                  {count
                                    .reduce((acc, { campaign_id, add_to_carts }) => {
                                      if (campaign_id === item._id) {
                                        return acc + add_to_carts;
                                      }
                                      return acc;
                                    }, 0)
                                    .toFixed(0)}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignPurchases || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignlikes || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaigncomments || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignEngagement?.toFixed(2) || 0}%
                                </td>
                                {opts1 && (
                                  <td className="px-4 py-2">
                                    {item.applicationStatusTimeline.length > 0
                                      ? item.applicationStatusTimeline.reduce(
                                        (acc, { status }) => {
                                          if (status === "Disapproved") {
                                            return acc + 1;
                                          }
                                          return acc;
                                        },
                                        0
                                      )
                                      : 0}
                                  </td>
                                )}
                                {opts2 && (
                                  <td className="px-4 py-2">
                                    {count
                                      .reduce((acc, { campaign_id, hits }) => {
                                        if (campaign_id === item._id) {
                                          return acc + hits;
                                        }
                                        return acc;
                                      }, 0)
                                      .toFixed(0)}
                                  </td>
                                )}
                                {opts3 && (
                                  <td className="px-4 py-2">
                                    {item?.campaignFollowers || 0}
                                  </td>
                                )}
                                {opts4 && (
                                  <td className="px-4 py-2">
                                    {count
                                      .reduce(
                                        (
                                          acc,
                                          {
                                            campaign_id,
                                            add_to_carts,
                                            hits,
                                            no_of_purchases,
                                          }
                                        ) => {
                                          if (campaign_id === item._id)
                                            return (
                                              acc +
                                              hits +
                                              no_of_purchases +
                                              add_to_carts
                                            );
                                          return acc;
                                        },
                                        0
                                      )
                                      .toFixed(0)}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-2 text-center text-xl text-gray-500 font-semibold "
                              >
                                No Active Campaigns Found !
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <PaginationComponent
                    currentPage={currentPage2}
                    itemsPerPage={rowsPerPage}
                    totalItems={active.length}
                    onPageChange={handlePageChange2}
                  />
                </div>
              </div>
            </CustomTabPanel>
            {/* active campaigns ends */}

            {/* finshed campaign starts */}
            <CustomTabPanel value={value} index={2}>
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4 space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-semibold text-black">
                  Finished Campaigns
                </h1>
                <div className="relative ml-auto">
                  {finished?.length > 0 && (
                    <button
                      className="w-8 h-8 p-1.5 text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOption(!option)}
                      aria-label="Toggle options"
                    >
                      <img
                        className="w-full h-full"
                        src="/Images/option.png"
                        alt=""
                      />
                    </button>
                  )}
                  {option && (
                    <div className="absolute right-0 top-full mt-2 bg-white shadow-lg rounded-lg overflow-hidden z-10">
                      <div className="p-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 w-max">
                        {[
                          { state: opt1, setter: setOpt1, label: "Rejected" },
                          { state: opt2, setter: setOpt2, label: "Clicks" },
                          {
                            state: opt3,
                            setter: setOpt3,
                            label: "Total Followers",
                          },
                          {
                            state: opt4,
                            setter: setOpt4,
                            label: "Conversions",
                          },
                        ].map((item, index) => (
                          <button
                            key={index}
                            className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md  ${item.state
                              ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                              : "bg-white hover:bg-blue-50"
                              }`}
                            onClick={() => item.setter(!item.state)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-lg overflow-hidden bg-white shadow-lg">
                <div className="w-full overflow-x-scroll">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="px-4 py-2">Name</th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("createdAt")}
                        >
                          Date
                        </th>
                        <th className="px-4 py-2">Status</th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("InfluencersRequired")}
                        >
                          No. of Influencers
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("posted")}
                        >
                          Posted
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("addtocarts")}
                        >
                          Add to Carts
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("campaignPurchases")}
                        >
                          Purchases
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("campaignLikes")}
                        >
                          Likes
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("campaigncomments")}
                        >
                          Comments
                        </th>
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort3("campaignEngagement")}
                        >
                          Engagement Rate
                        </th>
                        {opt1 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort3("rejected")}
                          >
                            Rejected
                          </th>
                        )}
                        {opt2 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort3("clicks")}
                          >
                            Clicks
                          </th>
                        )}
                        {opt3 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort3("followers")}
                          >
                            Total Followers
                          </th>
                        )}
                        {opt4 && (
                          <th
                            className="px-4 py-2"
                            onClick={() => handleSort3("conversions")}
                          >
                            Conversions
                          </th>
                        )}
                        {opt5 && <th className="px-4 py-2">In Reviews</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {loadingTableData ? (
                        <div>
                          <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                            Loading Finished Campaigns ....
                          </h1>
                        </div>
                      ) : (
                        <>
                          {currFinishedData && currFinishedData.length > 0 ? (
                            currFinishedData.map((item) => (
                              <tr
                                key={item._id}
                                className="border-b hover:bg-gray-100 text-[#707070] font-[500] text-center"
                              >
                                <td className="px-4 py-2 text-left">
                                  {item.name}
                                </td>
                                <td className="px-4 py-2">
                                  {formateDate(item.createdAt)}
                                </td>
                                <td className="px-4 py-2">
                                  <span
                                    className={`px-2 py-1 text-sm font-bold rounded-md text-red-600`}
                                  >
                                    Inactive
                                  </span>
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === "Approved") {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status, post_url }) => {
                                        if (
                                          status === "Approved" &&
                                          post_url
                                        ) {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="px-4 py-2">
                                  {count
                                    .reduce((acc, { campaign_id, add_to_carts }) => {
                                      if (campaign_id === item._id) {
                                        return acc + add_to_carts;
                                      }
                                      return acc;
                                    }, 0)
                                    .toFixed(0)}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignPurchases || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignLikes || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaigncomments || 0}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {item?.campaignEngagement?.toFixed(2) || 0}%
                                </td>
                                {opt1 && (
                                  <td className="px-4 py-2">
                                    {item.applicationStatusTimeline.length > 0
                                      ? item.applicationStatusTimeline.reduce(
                                        (acc, { status }) => {
                                          if (status === "Disapproved") {
                                            return acc + 1;
                                          }
                                          return acc;
                                        },
                                        0
                                      )
                                      : 0}
                                  </td>
                                )}
                                {opt2 && (
                                  <td className="px-4 py-2">
                                    {count
                                      ?.reduce((acc, { campaign_id, hits }) => {
                                        if (campaign_id === item._id) {
                                          return acc + hits;
                                        }
                                        return acc;
                                      }, 0)
                                      .toFixed(0)}
                                  </td>
                                )}
                                {opt3 && (
                                  <td className="px-4 py-2">
                                    {item?.campaignFollowers}
                                  </td>
                                )}
                                {opt4 && (
                                  <td className="px-4 py-2">
                                    {count
                                      .reduce(
                                        (
                                          acc,
                                          {
                                            campaign_id,
                                            add_to_carts,
                                            hits,
                                            no_of_purchases,
                                          }
                                        ) => {
                                          if (campaign_id === item._id)
                                            return (
                                              acc +
                                              hits +
                                              no_of_purchases +
                                              add_to_carts
                                            );
                                          return acc;
                                        },
                                        0
                                      )
                                      .toFixed(0)}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-2 text-center text-xl text-gray-500 font-semibold "
                              >
                                No Finished Campaigns Found !
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <PaginationComponent
                    currentPage={currentPage3}
                    itemsPerPage={rowsPerPage}
                    totalItems={finished.length}
                    onPageChange={handlePageChange3}
                  />
                </div>
              </div>
            </CustomTabPanel>
            {/* finshed campaign ends */}

            {/* influencer campaign starts */}
            <CustomTabPanel value={value} index={3}>
              <div className="flex items-center justify-between py-4">
                <div className="text-2xl font-semibold text-black">
                  Top Influencers
                </div>
                <div
                  className="bg-blue-400 rounded-lg text-lg p-2 font-semibold text-white shadow-md cursor-pointer"
                  onClick={() => handleMarketPlace()}
                >
                  Hire More
                </div>
              </div>
              <div className="rounded-lg bg-white p-5 overflow-x-scroll">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2">INFLUENCER</th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort4("spent")}
                      >
                        Spend
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort4("content")}
                      >
                        Content
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort4("revenue")}
                      >
                        Revenue
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loadingTableData ? (
                      <div>
                        <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                          Loading Influencers Data....
                        </h1>
                      </div>
                    ) : (
                      <>
                        {influencerData && influencerData.length > 0 ? (
                          influencerData.map((item) => (
                            <tr
                              key={item.id}
                              className="border-b hover:bg-gray-100 text-[#707070] font-[500] text-center"
                            >
                              <td className="px-4 py-2  text-center">
                                <div className="flex items-center space-x-2 ">
                                  <span className="text-gray-700 w-full">
                                    {item.name}
                                  </span>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <span className="text-gray-700 w-full">
                                    {item.spent.toFixed(2)}
                                  </span>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <span className="text-gray-700 w-full">
                                    {item.content}
                                  </span>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <span className="text-gray-700 w-full">
                                    {item?.revenue?.toFixed(2)}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="9"
                              className="px-4 py-2 text-center text-xl text-gray-500 font-semibold "
                            >
                              No Influencers Found !
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </CustomTabPanel>
            {/* influencer campaign ends */}

            {/* brand awarness campaign list starts */}
            <CustomTabPanel value={value} index={4}>
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4 space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-semibold text-black">
                  Brand Awareness Campaigns List
                </h1>
                <div className="relative ml-auto">
                  {brands?.length > 0 && (
                    <button
                      className="w-8 h-8 p-1.5 text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOption(!option)}
                      aria-label="Toggle options"
                    >
                      <img
                        className="w-full h-full"
                        src="/Images/option.png"
                        alt=""
                      />
                    </button>
                  )}
                  {option && (
                    <div className="absolute right-0 top-full mt-2 bg-white shadow-lg rounded-lg overflow-hidden z-10">
                      <div className="p-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 w-max">
                        {[
                          { state: opts1, setter: setOpts1, label: "Rejected" },
                          { state: opts2, setter: setOpts2, label: "Clicks" },
                          {
                            state: opts3,
                            setter: setOpts3,
                            label: "Total Followers",
                          },
                          {
                            state: opts4,
                            setter: setOpts4,
                            label: "Conversions",
                          },
                        ].map((item, index) => (
                          <button
                            key={index}
                            className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md  ${item.state
                              ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                              : "bg-white hover:bg-blue-50"
                              }`}
                            onClick={() => item.setter(!item.state)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="rounded-lg bg-white p-5 overflow-x-scroll font-semibold">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2 text-left">Name</th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("createdAt")}
                      >
                        Date
                      </th>
                      <th className="px-4 py-2">Status</th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("InfluencersRequired")}
                      >
                        No. of Influencers
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("posted")}
                      >
                        Posted
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("addtocarts")}
                      >
                        Add to Carts
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("campaignPurchases")}
                      >
                        Purchases
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("campaignLikes")}
                      >
                        Likes
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("campaignComments")}
                      >
                        Comments
                      </th>
                      <th
                        className="px-4 py-2"
                        onClick={() => handleSort5("campaignEngagement")}
                      >
                        Engagement Rate
                      </th>
                      {opts1 && (
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort5("rejected")}
                        >
                          Rejected
                        </th>
                      )}
                      {opts2 && (
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort5("clicks")}
                        >
                          Clicks
                        </th>
                      )}
                      {opts3 && (
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort5("followers")}
                        >
                          Total Followers
                        </th>
                      )}
                      {opts4 && (
                        <th
                          className="px-4 py-2"
                          onClick={() => handleSort5("conversions")}
                        >
                          Conversions
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {loadingTableData ? (
                      <div>
                        <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                          Loading BrandAwareness Campaigns Data....
                        </h1>
                      </div>
                    ) : (
                      <>
                        {currBrandAwarenessData &&
                          currBrandAwarenessData.length > 0 ? (
                          currBrandAwarenessData.map((item) => (
                            <tr
                              key={item._id}
                              className="border-b hover:bg-gray-100 text-[#707070] font-[500] text-center"
                            >
                              <td className="px-4 py-2 text-left">
                                {item.name}
                              </td>
                              <td className="px-4 py-2">
                                {formateDate(item.createdAt)}
                              </td>
                              <td className="px-4 py-2">
                                <span
                                  className={`px-2 py-1 text-sm font-semibold rounded-md ${getStatusColor(
                                    item?.statusCampaign
                                  )}`}
                                >
                                  {item?.statusCampaign}
                                </span>
                              </td>
                              <td className="px-4 py-2">
                                {item.applicationStatusTimeline.length > 0
                                  ? item.applicationStatusTimeline.reduce(
                                    (acc, { status }) => {
                                      if (status === "Approved") {
                                        return acc + 1;
                                      }
                                      return acc;
                                    },
                                    0
                                  )
                                  : 0}
                              </td>
                              <td className="px-4 py-2">
                                {item.applicationStatusTimeline.length > 0
                                  ? item.applicationStatusTimeline.reduce(
                                    (acc, { status, post_url }) => {
                                      if (status === "Approved" && post_url) {
                                        return acc + 1;
                                      }
                                      return acc;
                                    },
                                    0
                                  )
                                  : 0}
                              </td>

                              <td className="px-4 py-2">
                                {count
                                  .reduce((acc, { campaign_id, add_to_carts }) => {
                                    if (campaign_id === item._id) {
                                      return acc + add_to_carts;
                                    }
                                    return acc;
                                  }, 0)
                                  .toFixed(0)}
                              </td>
                              <td className="px-4 py-2 text-center">
                                {item?.campaignPurchases || 0}
                              </td>
                              <td className="px-4 py-2 text-center">
                                {item?.campaignLikes || 0}
                              </td>
                              <td className="px-4 py-2 text-center">
                                {item?.campaignComments || 0}
                              </td>
                              <td className="px-4 py-2 text-center">
                                {item?.campaignEngagement?.toFixed(2) || 0}%
                              </td>


                              {opts1 && (
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === "Disapproved") {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                              )}
                              {opts2 && (
                                <td className="px-4 py-2">
                                  {count
                                    .reduce((acc, { campaign_id, hits }) => {
                                      if (campaign_id === item._id) {
                                        return acc + hits;
                                      }
                                      return acc;
                                    }, 0)
                                    .toFixed(0)}
                                </td>
                              )}
                              {opts3 && (
                                <td className="px-4 py-2">
                                  {item?.campaignFollowers || 0}
                                </td>
                              )}
                              {opts4 && (
                                <td className="px-4 py-2">
                                  {count
                                    .reduce(
                                      (
                                        acc,
                                        {
                                          campaign_id,
                                          add_to_carts,
                                          hits,
                                          no_of_purchases,
                                        }
                                      ) => {
                                        if (campaign_id === item._id)
                                          return (
                                            acc +
                                            hits +
                                            no_of_purchases +
                                            add_to_carts
                                          );
                                        return acc;
                                      },
                                      0
                                    )
                                    .toFixed(0)}
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="9"
                              className="px-4 py-2 text-center text-xl text-gray-500 font-semibold "
                            >
                              No BrandAwareness Campaigns Found !
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>

                <PaginationComponent
                  currentPage={currentPage4}
                  itemsPerPage={rowsPerPage}
                  totalItems={brands.length}
                  onPageChange={handlePageChange4}
                />


              </div>
            </CustomTabPanel>
            {/* brand awarness campaign list ends */}

            {/* all campaigns starts */}
            <CustomTabPanel value={value} index={5}>
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4 space-y-4 sm:space-y-0">
                <h1 className="text-2xl font-semibold text-gray-800">
                  All Campaigns
                </h1>
                <div className="flex items-center space-x-4">
                  {option2 && (
                    <div className="bg-white shadow-md rounded-lg p-2 grid grid-cols-2 sm:grid-cols-3 gap-2">
                      {[
                        {
                          state: oopts1,
                          setter: setOopts1,
                          label: "Total No. Of Likes",
                        },
                        {
                          state: oopts2,
                          setter: setOopts2,
                          label: "Total No. Of Comments",
                        },
                        {
                          state: oopts3,
                          setter: setOopts3,
                          label: "Total No. Of Purchases",
                        },
                      ].map((item, index) => (
                        <button
                          key={index}
                          className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md ${item.state
                            ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                            : "bg-white hover:bg-blue-50"
                            }`}
                          onClick={() => item.setter(!item.state)}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  )}
                  {AllCamp?.length > 0 && (
                    <img
                      className="w-5 h-5 cursor-pointer"
                      src="/Images/option.png"
                      alt="option"
                      onClick={() => setOption2(!option2)}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className="rounded-lg relative bg-white py-[10px] w-[95%] p-5 overflow-x-scroll md:w-full font-semibold">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="text-sm pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-3">
                          Campaign Name
                        </th>
                        <th
                          className="text-sm pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-3"
                          onClick={() => handleSort6("createdAt")}
                        >
                          Date
                        </th>
                        <th
                          className="text-sm pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-3"
                        >
                          Status
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("InfluencersRequired")}
                        >
                          No. Of <br /> Influencers
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("campaignEngagement")}
                        >
                          Engagment
                          <br /> Rate
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("followers")}
                        >
                          Total No. Of Followers
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("clicks")}
                        >
                          Total No. Of Clicks
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("addtocarts")}
                        >
                          Add to Carts
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("conversions")}
                        >
                          Conversions
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("posted")}
                        >
                          Posted
                        </th>
                        <th
                          className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"
                          onClick={() => handleSort6("rejected")}
                        >
                          Rejected
                        </th>

                        {oopts1 && (
                          <th
                            className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold"
                            onClick={() => handleSort6("likes")}
                          >
                            {/* Rejected */}
                            Total No. Of Likes
                          </th>
                        )}
                        {oopts2 && (
                          <th
                            className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold"
                            onClick={() => handleSort6("comments")}
                          >
                            {/* Clicks */}
                            Total No. Of Comments
                          </th>
                        )}
                        {oopts3 && (
                          <th
                            className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold"
                            onClick={() => handleSort6("purchases")}
                          >
                            {/* Total Followers */}
                            Total No. Of Purchases
                          </th>
                        )}
                        {oopts4 && (
                          <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                            {/* Conversions */}
                            additional
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody className="my-[20px] mx-[20px] space-y-[20px] ">
                      {loadingTableData ? (
                        <div>
                          <h1 className="text-2xl text-gray-500 ml-5 mt-5">
                            Loading All Campaigns Data....
                          </h1>
                        </div>
                      ) : (
                        <>
                          {currAllCampData &&
                            currAllCampData?.map((item) => (
                              <tr
                                key={item?._id}
                                className="border-b hover:bg-gray-100 text-center text-[#707070] font-[500]"
                              >
                                <td className="px-4 py-2 text-left">
                                  {item?.name}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {formateDate(item?.createdAt)}
                                </td>
                                <td className="px-4 py-2 text-left">
                                  <span
                                    className={`px-2 py-1 text-sm font-semibold rounded-md ${getStatusColor(
                                      item.statusCampaign
                                    )}`}
                                  >
                                    {item.statusCampaign}
                                  </span>
                                </td>
                                <td className="px-4 py-2">
                                  {item?.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === "Approved") {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="px-4 py-2">
                                  {item?.campaignEngagement?.toFixed(2) || 0}%
                                </td>
                                <td className="px-4 py-2">
                                  {item?.campaignFollowers || 0}
                                </td>
                                <td className="px-4 py-2">
                                  {count &&
                                    count
                                      ?.reduce((acc, { campaign_id, hits }) => {
                                        if (campaign_id === item._id) {
                                          return acc + hits;
                                        }
                                        return acc;
                                      }, 0)
                                      .toFixed(0)}
                                </td>
                                <td className="px-4 py-2">
                                  {count &&
                                    count
                                      ?.reduce((acc, { campaign_id, add_to_carts }) => {
                                        if (campaign_id === item._id) {
                                          return acc + add_to_carts;
                                        }
                                        return acc;
                                      }, 0)
                                      .toFixed(0)}
                                </td>
                                <td className="px-4 py-2">
                                  {count
                                    .reduce(
                                      (
                                        acc,
                                        {
                                          campaign_id,
                                          add_to_carts,
                                          hits,
                                          no_of_purchases,
                                        }
                                      ) => {
                                        if (campaign_id === item._id)
                                          return (
                                            acc +
                                            hits +
                                            no_of_purchases +
                                            add_to_carts
                                          );
                                        return acc;
                                      },
                                      0
                                    )
                                    .toFixed(0)}
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status, post_url }) => {
                                        if (status === "Approved" && post_url) {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="px-4 py-2">
                                  {item.applicationStatusTimeline.length > 0
                                    ? item.applicationStatusTimeline.reduce(
                                      (acc, { status }) => {
                                        if (status === "Disapproved") {
                                          return acc + 1;
                                        }
                                        return acc;
                                      },
                                      0
                                    )
                                    : 0}
                                </td>
                                {oopts1 && (
                                  <td className="px-4 py-2">
                                    {item?.campaignLikes || 0}
                                  </td>
                                )}
                                {oopts2 && (
                                  <td className="px-4 py-2">
                                    {item?.campaignComments || 0}
                                  </td>
                                )}
                                {oopts3 && (
                                  <td className="px-4 py-2">
                                    {item?.campaignPurchases || 0}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  <PaginationComponent
                    currentPage={currentPage5}
                    itemsPerPage={rowsPerPage}
                    totalItems={AllCamp.length}
                    onPageChange={handlePageChange5}
                  />
                </div>
              </div>
              {/* affiliate campaign table ends */}
            </CustomTabPanel>
            {/* all campaigns ends */}
          </Box>
        </div>
      </div>
      {loadingAcStatus && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg">
            <h1 className="text-lg font-semibold">Loading...</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default Main;
