import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import moment from "moment";
import ReactModal from 'react-modal'; // Ensure you install react-modal
import axios from 'axios';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  FaBell, FaPlus, FaEdit, FaTrash, FaChartBar, FaSignal, FaUsers, FaDollarSign,
  FaStore,
  FaHandshake,
} from 'react-icons/fa';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from 'chart.js';
import ApiInterceptor from '../../utils/ApiInterceptor';

// Chart.js Registration
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
 

  const formatNumber = (num) => {
    if (num < 1000) {
      return num;
    } else if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000 && num < 1000000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else {
      return (num / 1000000000).toFixed(1) + 'B';
    }
  };
  
  // Data fetching
  useEffect(() => {
    setTimeout(() => {
      ApiInterceptor.get('admin/notifications/dashboarddata')
        .then((response) => {
          setData(response.data.data);
          setNotifications(response.data.data.notifications || []);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    }, 1000); // Simulate delay for loaders
  }, []);

  // Notification Handlers
  const toggleNotificationPanel = () => setShowNotificationPanel((prev) => !prev);

 

  // Percentage Change Calculation
  const calculatePercentageChange = (weekly, total) => {
    if (total === 0) return '0%'; // Avoid division by zero
    const percentage = (weekly / total) * 100;
    return `${percentage.toFixed(2)}% of Total`;
  };
  

  // Shimmer Loader
  const shimmerLoader = () => (
    <div className="animate-pulse space-y-4">
      <div className="h-24 bg-gray-300 rounded-lg"></div>
      <div className="h-72 bg-gray-300 rounded-lg"></div>
      <div className="grid grid-cols-2 gap-4">
        <div className="h-64 bg-gray-300 rounded-lg"></div>
        <div className="h-64 bg-gray-300 rounded-lg"></div>
      </div>
    </div>
  );

  // Chart Configurations
  const barChartData = {
    labels: ['Revenue', 'Businesses', 'Influencers', 'Campaigns'],
    datasets: [
      {
        label: 'Total',
        data: [
          data?.revenue.total,
          data?.businesses.total,
          data?.influencers.total,
          data?.campaigns.total,
        ],
        backgroundColor: '#7b77fc',
      },
      {
        label: 'Weekly',
        data: [
          data?.revenue.weekly,
          data?.businesses.weekly,
          data?.influencers.weekly,
          data?.campaigns.weekly,
        ],
        backgroundColor: '#af77fc',
      },
    ],
  };

  const lineChartData = {
    labels: ['Instagram', 'YouTube'],
    datasets: [
      {
        label: 'Reach',
        data: [data?.instagram.totalReach, data?.youtube.totalReach],
        borderColor: '#3B82F6',
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        tension: 0.4, // Smooth curve
        pointBorderColor: '#2563EB',
        pointBackgroundColor: '#2563EB',
        fill: true,
      },
    ],
  };

  const pieChartData = {
    labels: ['Instagram Followers', 'YouTube Subscribers'],
    datasets: [
      {
        data: [data?.instagram.totalFollowers, data?.youtube.totalSubscribers],
        backgroundColor: ['#4CAF50', '#FF5722'],
        hoverBackgroundColor: ['#388E3C', '#E64A19'],
      },
    ],
  };

  // Render
  if (loading) return shimmerLoader();

  return (
    <div className="bg-gray-100 min-h-screen px-8 py-3 relative">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-4xl font-bold text-gray-700">Dashboard</h1>
        <button onClick={toggleNotificationPanel} className="relative text-3xl text-gray-800">
          <FaBell />
          {notifications.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {notifications.length}
            </span>
          )}
        </button>
      </div>


      {showNotificationPanel && <NotificationPanel notification={notifications} setNotification={setNotifications}
      />}




      {/* Analytics Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {/* Revenue */}
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg">
          <div>
            <h3 className="text-xl">Revenue</h3>
            <p className="text-3xl">₹{data.revenue.total.toFixed(3)}</p>
            <p className="text-sm">
              {calculatePercentageChange(data.revenue.weekly, data.revenue.total)}
            </p>
          </div>
          <FaDollarSign size={30} />
        </div>

        {/* Businesses */}
        <div className="bg-gradient-to-r from-green-500 to-green-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg">
          <div>
            <h3 className="text-xl">Businesses</h3>
            <p className="text-3xl">{data.businesses.total}</p>
            <p className="text-sm">
              {calculatePercentageChange(data.businesses.weekly, data.businesses.total)}
            </p>
          </div>
          <FaChartBar size={30} />
        </div>

        {/* Influencers */}
        <div className="bg-gradient-to-r from-yellow-500 to-yellow-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg">
          <div>
            <h3 className="text-xl">Influencers</h3>
            <p className="text-3xl">{data.influencers.total}</p>
            <p className="text-sm">
              {calculatePercentageChange(data.influencers.weekly, data.influencers.total)}
            </p>
          </div>
          <FaUsers size={30} />
        </div>

        {/* Campaigns */}
        <div className="bg-gradient-to-r from-red-500 to-red-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg">
          <div>
            <h3 className="text-xl">Campaigns</h3>
            <p className="text-3xl">{data.campaigns.total}</p>
            <p className="text-sm">
              {calculatePercentageChange(data.campaigns.weekly, data.campaigns.total)}
            </p>
          </div>
          <FaSignal size={30} />
        </div>
      </div>

      {/* Bar Chart */}
      <div className="mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl text-center text-gray-700 mb-4">Metrics Overview</h3>
          <Bar data={barChartData} />
        </div>
      </div>


{/* Campaigns Overview Row */}
<div className="flex space-x-6 mb-8 items-stretch">
  {/* Graph Section */}
  <div className="w-3/5 bg-white p-6 rounded-lg shadow-md flex flex-col">
    <h3 className="text-xl text-gray-700 mb-4 text-center">Campaigns Distribution</h3>
    <div className="w-full h-full">
    <Bar
  data={{
    labels: ['Affiliate Weekly', 'Affiliate Total', 'Marketplace Weekly', 'Marketplace Total'],
    datasets: [
      {
        label: 'Campaigns Data',
        data: [
          data.affiliateCampaigns.weekly,
          data.affiliateCampaigns.total,
          data.marketplaceCampaigns.weekly,
          data.marketplaceCampaigns.total,
        ],
        backgroundColor: ['#FF9800', '#4CAF50', '#FF5722', '#3F51B5'],
      },
    ],
  }}
  options={{
    indexAxis: 'y', // Makes the bar graph horizontal
    plugins: {
      legend: { display: false },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 1, // Ensure only integers are shown
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Show only integers
          },
        },
      },
    },
  }}
/>

    </div>
  </div>

  {/* Cards Section */}
  <div className="flex flex-col w-2/5 space-y-6">
    {/* Affiliate Campaigns Card */}
    <div className="bg-gradient-to-r from-indigo-500 to-indigo-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg h-full">
      <div>
        <h3 className="text-xl">Affiliate Campaigns</h3>
        <p className="text-3xl">{data.affiliateCampaigns.total}</p>
        <p className="text-sm">
          {calculatePercentageChange(data.affiliateCampaigns.weekly, data.affiliateCampaigns.total)}
        </p>
      </div>
      <FaHandshake size={30} />
    </div>

    {/* Marketplace Campaigns Card */}
    <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6 rounded-lg text-white flex items-center justify-between shadow-lg h-full">
      <div>
        <h3 className="text-xl">Marketplace Campaigns</h3>
        <p className="text-3xl">{data.marketplaceCampaigns.total}</p>
        <p className="text-sm">
          {calculatePercentageChange(data.marketplaceCampaigns.weekly, data.marketplaceCampaigns.total)}
        </p>
      </div>
      <FaStore size={30} />
    </div>
  </div>
</div>

      {/* Charts */}
    <div className="flex space-x-8 mb-8">
  {/* Pie Chart for Followers Distribution */}
  <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col justify-between items-center">
    <h3 className="text-xl text-gray-700 mb-4 text-center">Followers Distribution</h3>
    <div className="flex justify-center items-center w-full h-[300px]">
      <Pie data={pieChartData} className="w-full h-full" />
    </div>
    {/* Total Data */}
    <div className="mt-4 flex justify-around w-full text-sm text-gray-600">
      {/* Instagram Followers */}
      <div className="flex items-center space-x-2">
        <FaUsers className="text-blue-500" size={20} />
        <span>
        Instagram: <strong>{formatNumber(data?.instagram.totalFollowers)}</strong>
        </span>
      </div>
      {/* YouTube Subscribers */}
      <div className="flex items-center space-x-2">
        <FaUsers className="text-red-500" size={20} />
        <span>
          YouTube: <strong>{formatNumber(data?.youtube.totalSubscribers)}</strong>
        </span>
      </div>
    </div>
  </div>

  {/* Line Chart for Reach Comparison */}
  <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col justify-between items-center">
    <h3 className="text-xl text-gray-700 mb-4 text-center">Reach Comparison</h3>
    <div className="flex justify-center items-center w-full h-[300px]">
      <Line
        data={lineChartData}
        className="w-full h-full"
        options={{
          plugins: {
            legend: { display: false },
          },
          maintainAspectRatio: false,
        }}
      />
    </div>
    {/* Total Data */}
    <div className="mt-4 flex justify-around w-full text-sm text-gray-600">
      {/* Instagram Reach */}
      <div className="flex items-center space-x-2">
        <FaSignal className="text-blue-500" size={20} />
        <span>
          Instagram: <strong>{formatNumber(data?.instagram.totalReach)}</strong>
        </span>
      </div>
      {/* YouTube Reach */}
      <div className="flex items-center space-x-2">
        <FaSignal className="text-red-500" size={20} />
        <span>
          YouTube: <strong>{formatNumber(data?.youtube.totalReach)}</strong>
        </span>
      </div>
    </div>
  </div>
</div>






    </div>
  );
};


const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the screen when modal is open
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
    border: "none",
    background: "white",
  },
};


const NotificationPanel = ({ notification, setNotification}) => {
  const [notifications, setNotifications] = useState(notification);
  const [newNotificationTitle, setNewNotificationTitle] = useState("");
  const [newNotificationDesc, setNewNotificationDesc] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("app");
  const [editNotification, setEditNotification] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);

  const fetchNotifications = async () => {
    try {
      const response = await ApiInterceptor.get(`admin/notifications/getallnotifications?platform=${selectedPlatform}`
      );
      setNotifications(response.data);
    } catch (error) {
      toast.error("Failed to fetch notifications");
    }
  };

  const handleAddNotification = async () => {
    if (!newNotificationTitle.trim()) {
      return toast.error("Notification title is required");
    }
    if (!newNotificationDesc.trim()) {
      return toast.error("Notification description is required");
    }
    if (!selectedPlatform) {
      return toast.error("Please select a platform");
    }

    try {
      await ApiInterceptor.post("admin/notifications/postnotifications", {
        data: {
          notificationTitle: newNotificationTitle,
          notificationDesc: newNotificationDesc,
          platform: selectedPlatform,
        },
      });
      toast.success("Notification added successfully");
      setNotification((prev)=>[...prev,{new:true}]);
      setNewNotificationTitle("");
      setNewNotificationDesc("");
      fetchNotifications();
    } catch (error) {
      toast.error("Failed to add notification");
    }
  };

  const handleEditNotification = async () => {
    if (!editNotification.title.trim() || !editNotification.desc.trim()) {
      return toast.error("Both title and description are required");
    }

    try {
      await ApiInterceptor.put("admin/notifications/updatenotifications", {
        id: editNotification.id,
        data: {
          notificationTitle: editNotification.title,
          notificationDesc: editNotification.desc,
          platform: editNotification.platform,
        },
      });
      toast.success("Notification updated successfully");
      setEditNotification(null);
      fetchNotifications();
    } catch (error) {
      toast.error("Failed to update notification");
    }
  };

  const handleDeleteNotification = async () => {
    try {
      await ApiInterceptor.delete("admin/notifications/deletenotifications", {
        data: { id: notificationToDelete },
      });
      toast.success("Notification deleted successfully");
      setNotification((prev)=>prev.splice(1));
      setShowConfirmModal(false);
      fetchNotifications();
    } catch (error) {
      toast.error("Failed to delete notification");
    }
  };

  const handleCancelEdit = () => {
    setEditNotification(null);
  };

  useEffect(() => {
    fetchNotifications();
  }, [selectedPlatform]);

  return (
    <>
      <div className="absolute top-16 right-0 w-96 bg-white shadow-lg rounded-lg p-6 overflow-y-auto h-[calc(100vh-80px)] scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-blue-400 scrollbar-track-gray-200"
           style={{ scrollbarWidth: "thin", WebkitScrollbar: "thin", WebkitScrollbarTrack: "gray" }}>
        <h4 className="text-lg font-semibold mb-4 text-gray-800 border-b pb-2">Notifications</h4>

        {/* Platform Selector */}
        <div className="mb-4">
          <label htmlFor="platform" className="text-gray-700 font-semibold">
            Select Platform
          </label>
          <select
            id="platform"
            value={selectedPlatform}
            onChange={(e) => setSelectedPlatform(e.target.value)}
            className="w-full border p-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500"
          >
            <option value="app">App</option>
            <option value="website">Website</option>
          </select>
        </div>

        {/* Notifications List */}
        <div className="space-y-4 max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-gray-300 scrollbar-track-gray-100"  style={{ scrollbarWidth: "thin", WebkitScrollbar: "thin", WebkitScrollbarTrack: "gray" }}>
          {notifications.length === 0 ? (
            <p className="text-gray-500">No notifications available</p>
          ) : (
            notifications.map((notification) => (
              <div
                key={notification._id}
                className="flex flex-col bg-gray-100 rounded-lg shadow-md p-4 w-full max-w-full overflow-hidden"
              >
                <div className="flex items-center mb-2">
                  <span
                    className={`px-3 py-1 text-sm text-white rounded-full ${notification.platform === "app" ? "bg-blue-500" : "bg-green-500"}`}
                  >
                    {notification.platform === "app" ? "App" : "Website"}
                  </span>
                </div>
                {editNotification?.id === notification._id ? (
                  <div>
                    <div>
                      <label className="text-gray-700 font-semibold">Platform</label>
                      <select
                        value={editNotification.platform}
                        onChange={(e) => setEditNotification({ ...editNotification, platform: e.target.value })}
                        className="w-full p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="app">App</option>
                        <option value="website">Website</option>
                      </select>
                    </div>
                    <div>
                      <label className="text-gray-700 font-semibold">Title</label>
                      <textarea
                        value={editNotification.title}
                        onChange={(e) => setEditNotification({ ...editNotification, title: e.target.value })}
                        className="w-full p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 resize-none"
                      />
                    </div>
                    <div>
                      <label className="text-gray-700 font-semibold">Description</label>
                      <textarea
                        value={editNotification.desc}
                        onChange={(e) => setEditNotification({ ...editNotification, desc: e.target.value })}
                        className="w-full p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 resize-none"
                      />
                    </div>
                    <div className="flex justify-end mt-4 space-x-3">
                      <button
                        onClick={handleEditNotification}
                        className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="px-3 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="text-gray-800 font-semibold break-words">{notification.notificationTitle}</p>
                    <p className="text-gray-600 mt-2 break-words">{notification.notificationDesc}</p>
                    <p className="text-gray-500 text-xs mt-3">{moment(notification.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</p>
                    <div className="flex justify-end mt-4 space-x-3">
                      <button
                        onClick={() => setEditNotification({ id: notification._id, title: notification.notificationTitle, desc: notification.notificationDesc, platform: notification.platform })}
                        className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => {
                          setNotificationToDelete(notification._id);
                          setShowConfirmModal(true);
                        }}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </div>

        {/* Add Notification Form */}
        <div className="mt-4 space-y-4">
          <div>
            <label htmlFor="newNotificationTitle" className="text-gray-700 font-semibold">Title</label>
            <textarea
              id="newNotificationTitle"
              className="w-full p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 resize-none"
              value={newNotificationTitle}
              onChange={(e) => setNewNotificationTitle(e.target.value)}
              placeholder="Enter notification title"
            />
          </div>
          <div>
            <label htmlFor="newNotificationDesc" className="text-gray-700 font-semibold">Description</label>
            <textarea
              id="newNotificationDesc"
              className="w-full p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 resize-none"
              value={newNotificationDesc}
              onChange={(e) => setNewNotificationDesc(e.target.value)}
              placeholder="Enter notification description"
            />
          </div>
          <button
            onClick={handleAddNotification}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center hover:bg-blue-600 shadow-md"
          >
            <FaPlus className="mr-2" /> Add Notification
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ReactModal
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(false)}
        className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6 outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        ariaHideApp={false}
      >
        <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
        <p>Are you sure you want to delete this notification?</p>
        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={() => setShowConfirmModal(false)}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded text-gray-700 shadow"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteNotification}
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded shadow"
          >
            Delete
          </button>
        </div>
      </ReactModal>
    </>
  );
};




export default Dashboard;


